import React, { useEffect } from "react";

import {
  Route,
  BrowserRouter as Router,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { CoPresentOutlined,  } from "@mui/icons-material";
import { Button, FormControlLabel, Switch, TextField } from "@mui/material";
import UserIcon from "../../Assets/user.svg";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import { styled } from "@mui/material/styles";
import AuthContext from "../../Contexts/auth-context/AuthContext";
import Doctor from "../../Assets/doctor.svg";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3c78bc",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3c78bc",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3c78bc",
    },
    "&:hover fieldset": {
      borderColor: "#3c78bc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3c78bc",
    },
  },
});

export default function UserInfo_Card(props) {
  const { change_password } = React.useContext(AuthContext);
  const {
    foto,
    iconSustitute,
    username,
    fullname,
    sponsor,
    email,
    telephone,
    backcolor,
    imageColor,
    stadistics1_text,
    stadistics1_number,
    stadistics2_text,
    stadistics2_number,
    has_2fa_Prop,
    onChangeTwoFactor,
    emailVerf,
    ...other
  } = props;
  const [stateS, setState] = React.useState();
  const { editSpecialist } = React.useContext(GeneralContext);
  const [newPass_Error_Text, setNewPass_Error_Text] = React.useState("");
  const [newPass_Error_Visib, setNewPass_Error_Visib] = React.useState(false);

  const [newConfPass_Error_Text, setNewConfPass_Error_Text] =
    React.useState("");
  const [newConfPass_Error_Visib, setNewConfPass_Error_Visib] =
    React.useState(false);
  const [passActual, setPassActual] = React.useState("");
  const [passNew, setPassNew] = React.useState("");
  const [passNewConfirm, setPassNewConfirm] = React.useState("");
  const [alertInfo, setAlertInfo] = React.useState({});
  const [open, setopen] = React.useState(false);

  const onCancel = () => {    
    setPassActual("");
    setPassNew("");
    setPassNewConfirm("");
  };

  const changeEnabled = async () => {
    let val = stateS === 0 ? 1 : 0;
    setState(stateS === 0 ? 1 : 0);
    await editSpecialist({ state: val }, props.id);
    props.onChange();
  };

  useEffect(() => {
    setState(props.status);
  }, [props.status]);

  const onSave = async () => {
    setNewPass_Error_Visib(false);
    setNewConfPass_Error_Visib(false);
   
    if (passNewConfirm !== passNew && passNew !== "" && passNewConfirm !== "") {
      onCancel();
      //Las contraseñas deben coincidir
      setNewPass_Error_Text("Las contraseñas deben coincidir");
      setNewPass_Error_Visib(true);
      setNewConfPass_Error_Text("Las contraseñas deben coincidir");
      setNewConfPass_Error_Visib(true);
    } else {
      //Change Password
      const resp = await change_password({ new_password: passNew });
      if (resp?.errorStatus)
        setAlertInfo({
          visibility: true,
          type: "error",
          title: "Error",
          msg: "No se pudo cambiar la contraseña",
        });
      else
        setAlertInfo({
          visibility: true,
          type: "success",
          title: "Operacion exitosa",
          msg: "Contraseña cambiada",
        });
        setopen(false);
    }
  };
  

  return (
    <div className="card shadow  w-100 rounded-15">
      <Alert_MUI
        color={alertInfo.type}
        title={alertInfo.title}
        msg={alertInfo.msg}
        open={alertInfo.visibility}
        onClose={() => {
          setAlertInfo({
            visibility: false,
          });
        }}
      />
      <div className="card-title mt-3">
        {props.isSpecil  ? (
          <div className="circle ">
            <img src={Doctor} height="95%" width="95%" className="m-auto" />
          </div>
        ) : (
          <div className="circle">
            <img src={UserIcon} height="95%" width="95%" className="m-auto" />
          </div>
        )}
      </div>
      <div className="card-header">
        <div className="w-100 text-center">
          <h4>{fullname}</h4>
        </div>
        <div className="w-100 text-center">
          <h6 className="text-secondary">@{username}</h6>
        </div>
      </div>

      <div className="card-footer mt-2">
        <div className="row pb-2 justify-content-around align-content-around d-flex">
          <div className="col-12">
            <div className="row justify-content-around align-content-around d-flex">
              <div className="col-10 text-center">
                <h6 className="text-center">{email}</h6>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="row justify-content-center align-content-around d-flex">
              <div className="col-10 text-center">
                {emailVerf ? (
                  <h6 className="text-success text-center">
                    Correo verificado
                  </h6>
                ) : (
                  <h6 className="text-error text-center">
                    Correo sin verificar
                  </h6>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div className="row justify-content-center align-content-around d-flex">
              {props.isSpecil ? (
                <div className="col-lg-12 col-sm-12 col-12 p-1  mt-2 justify-content-center align-content-around d-flex">
                  <FormControlLabel
                    sx={{
                      display: "block",
                    }}
                    key={props.status}
                    control={
                      <Switch
                        checked={stateS === 0 ? false : true}
                        onChange={changeEnabled}
                        color="success"
                      />
                    }
                    label={stateS === 0 ? "Fuera de servicio" : "En línea"}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12 mt-2 d-flex justify-content-center">
            <button
              onClick={() => setopen(true)}
              className="button-Primary ms-auto me-auto"
            >
              Cambiar contraseña
            </button>
          </div>
          <div className="col-12 mt-2 d-flex justify-content-center">
            <a href="https://t.me/Telerob_bot">Únase al canal de Telegram</a>
            </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={()=>setopen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Cambiar contraseña</DialogTitle>
        <DialogContent>
          <div
            className="row  bg-transparent justify-content-start d-flex
           align-content-start "
          >
            <div className="col-lg-12 col-sm-12 col-12 p-1  mt-2">
              <CssTextField
                className="w-90"
                error={newPass_Error_Visib}
                label="Nueva contraseña"
                value={passNew}
                helperText={newPass_Error_Text}
                type={"password"}
                onChange={(ev) => {
                  setPassNew(ev.target.value);
                }}
              />
            </div>
            <div className="col-lg-12 col-sm-12 col-12 p-1  mt-2">
              <CssTextField
                className="w-90"
                error={newConfPass_Error_Visib}
                label="Confirmar nueva contraseña"
                value={passNewConfirm}
                helperText={newConfPass_Error_Text}
                type={"password"}
                onChange={(ev) => {
                  setPassNewConfirm(ev.target.value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="button-Secondary" autoFocus onClick={(ev)=>{ev.preventDefault(); setopen(false)}}>
            Cancelar
          </Button>
          <Button
          disabled={
          passNewConfirm === "" || passNew === ""
          }
            className="button-Primary"
            onClick={() => {
              onSave();
            }}
            autoFocus
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
