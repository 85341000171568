import React, { useState } from "react";
import TempleteReportes from "../../Components/Containers/TempleteReportes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import { Delete, Edit } from "@mui/icons-material";
import CustomDialog from "../../Components/Components/CustomDialog";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import NavBar_Bootstrap from "../../Components/Components/NavBars/NavBar_Bootstrap";
import exportFromJSON from "export-from-json";
import AlertDialog from "../../Components/Components/AlertDialog";

export default function SpecialistList(props) {
  const {
    addSpecialist,
    editSpecialist,
    getAllSpecialist,
    removeSpecialist,
    specialists,
    getAllSpecialisties,
    specialities,
    getAllMedicalClasifications,
    medicals_clasifications,
    getNotifcationSpecialist
  } = React.useContext(GeneralContext);
  const [filtrerBy, setfiltrerBy] = useState("");
  const [dataForLoad, setdataForLoad] = useState([]);
  const [view, setview] = useState("Todas");
  const [loading, setloading] = useState(true);
  const [justloading, setjustloading] = useState("show");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setpage] = useState(0);
  const [search, setsearch] = useState("");
  const [totalItems, settotalItems] = useState(0);
  const [orderSelected, setorderSelected] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [itemSelectedToDialog, setItemSlected] = useState({});
  const [operation, setOperation] = useState("New");
  const [alertColor, setAlertColor] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [visibilityAlertConfirm, setvisibilityAlertConfirm] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const arrayOfStatus = [
    {
      name: 1,
      color: "success",
      nameTraduced: "Disponible",
    },

    {
      name: 0,
      color: "warning",
      nameTraduced: "No disponible",
    },
  ];

  const onOrderSelect = (id) => {
   
    setorderSelected(id);
    setItemSlected({
      nombre_usuario: id.username,
      nombre: id.firstName,
      apellidos: id.lastName,
      correo: id.email,
      telefono: id.phone_number,
      medical_specialities: prepareSpecialities(id.medical_specialities),
      specialities: specialities,
      idioma_ingles: id.english,
    });
  };

  const onButtonEdit = () => {
   
    setOperation("Edit");
    //this.props.history.push("/orden/" + orderSelected.id);
    setOpenDialog(true);
  };

  const onButtonDelete = async () => {
    await removeSpecialist(orderSelected.id);
    setvisibilityAlertConfirm(false);
    setAlertColor("success");
    setAlertMsg("Especialista eliminado con éxito");
    setAlertTitle("Operación exitosa");
    setAlertOpen(true);
    await getAllSpecialist(false, undefined, "", search);
  };

  const onHandleSelectionViewOrders = (select) => {
    setview(select);
    setloading(true);
    setfiltrerBy(select);

    if (select === null || select === undefined) {
      select = "Todas";
    }

    //this.loadInfoReporte(select, rowsPerPage, page, search);
  };

  const OnClickNotify = (item) => {
    console.log("Espec selected",item);

    getNotifcationSpecialist(item.associated_user).then((next)=>{

      console.log("Response", next);

      if(next.errorStatus){
        setAlertColor("error");
        setAlertMsg("Ha ocurrido un error al notificar al especialista");
        setAlertTitle("Error");
        setAlertOpen(true);
      }
      else{
        setAlertColor("success");
        setAlertMsg("Especialista notificado con éxito");
        setAlertTitle("Operación exitosa");
        setAlertOpen(true);
      }
    });

  }

  const exportData = () => {
    const usersToExport = specialists.map((item) => {
      return {
        Nombre: item.firstName,
        Apellidos: item.lastName,
        Correo: item.email,
        Estado: item.state === 0 ? "Online" : "Offline",
      };
    });
    const fileName = "Especialistas";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: usersToExport, fileName, exportType });
  };

  React.useEffect(() => {
    if(search === "")
    getAllSpecialist(false, undefined, undefined, undefined).then((next)=>
    setloading(false));
    else
    getAllSpecialist(false, undefined, filtrerBy, search).then((next)=>
    setloading(false));
  }, [search]);

  React.useEffect(() => {
    getAllMedicalClasifications();
    getAllSpecialist(false, undefined, filtrerBy, search).then((next)=>
    setloading(false));
    getAllSpecialisties();
  }, []);

  React.useEffect(() => {   
  }, [visibilityAlertConfirm]);


  const onChangeRowPerPage = (rowsCount) => {
    setrowsPerPage(rowsCount);
    loadInfoReporte(view, rowsCount, page, search);
  };

  const onChangePage = (pageActually) => {
    setpage(pageActually);   
    loadInfoReporte(view, rowsPerPage, pageActually, search);
  };

  const loadInfoReporte = async (selection, rowsPerPage, pageNew, search) => {
    setloading(false);   
    await getAllSpecialist(selection);
 
  };

  const onChangeFiltrered = (ev) => {
    setfiltrerBy(ev.target.value);
    setloading(true);
  };

  const isDisabled = (row) => {
    if (row.state === "Terminado") return true;
    else return false;
  };

  const onChangeSearch = (filter, search) => {
    setloading(true);
    
    setsearch(search);
    setfiltrerBy(filter);
  };

  const onSaveData = async (data) => {    
    setLoadingDialog(true);
    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (data.correo && data.correo !== "" && !validEmail.test(data.correo)) {
      setAlertColor("error");
      setAlertMsg("Introduzca un correo válido");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    } 
    else if (data.telefono && (data.telefono.length < 7 || data.telefono.length > 10)) {
      setAlertColor("error");
      setAlertMsg("El teléfono debe tener entre 7 y 10 dígitos");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    }
    else if (data.medical_specialities && (data.medical_specialities.length ===0 )) {
      setAlertColor("error");
      setAlertMsg("Debe especificar la(s) especialidad(es)");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    }
    else {
      const dataToSave = {
        username: data.nombre_usuario,
        firstName: data.nombre,
        lastName: data.apellidos,
        email: data.correo,
        phone_number: data.telefono,
        medical_clasification_id: 1,
        priority: data.prioridad ? data.prioridad[0]?.value : 0,
        medical_specialties: data.medical_specialities,
        realmRoles: ["specialist"],
        disabled: data.disabled,
        english: data.idioma_ingles,
      };

      if (operation === "New") {
        const resp = await addSpecialist(dataToSave);

        if (resp?.errorStatus === undefined) {
          setAlertColor("success");
          setAlertMsg("Especialista añadido con éxito");
          setAlertTitle("Operación exitosa");
          setAlertOpen(true);
          setOpenDialog(false);
          loadInfoReporte(false, 10, 0, "");
          setorderSelected({});
          setItemSlected({});
          setLoadingDialog(false);
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409) setAlertMsg("No deben haber ni correos, ni nombres de usuarios, ni teléfonos repetidos");
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
          setLoadingDialog(false);
        }
      } else {
        if (data?.realmRoles?.length > 1) data.realmRoles = ["operator"];
        if (data.correo && data.correo !== "" && !validEmail.test(data.correo)) {
          setAlertColor("error");
          setAlertMsg("Introduzca un correo válido");
          setAlertTitle("Error");
          setAlertOpen(true);
          setLoadingDialog(false);
        } 
        else {
          const resp = await editSpecialist(
            {
              firstName: data.nombre,
              lastName: data.apellidos,
              email: data.correo,
              phone_number: data.telefono,
              priority: data.prioridad ? data.prioridad[0]?.value : 0,
              medical_specialties: data.medical_specialities,
              disabled: data.disabled,
              english: data.idioma_ingles
            },
            orderSelected.id
          );
          
  
          if (resp?.errorStatus === undefined) {
            setAlertColor("success");
            setAlertMsg("Especialista editado con éxito");
            setAlertTitle("Operación exitosa");
            setAlertOpen(true);
            setOpenDialog(false);
            loadInfoReporte(false, 10, 0, "");
            setorderSelected({});
            setItemSlected({});
            setLoadingDialog(false);
          } else {
            setAlertColor("error");
            if (resp?.errorStatus === 409) setAlertMsg("No deben haber ni correos, ni nombres de usuarios, ni teléfonos repetidos");
            else setAlertMsg("Ha ocurrido un error");         
            setAlertTitle("Error");
            setAlertOpen(true);
            setLoadingDialog(false);
          }
        }
        
      }
    }
  };

  const onClickButtonDelete = (item) => {
   
    setItemSlected({
      id: item.id,
      username: item.nombre_usuario,
      firstName: item.nombre,
      lastName: item.apellidos,
      email: item.correo,
    });
    setvisibilityAlertConfirm(true);
  };

  const inputData = {
    nombre_usuario: "",
    nombre: "",
    apellidos: "",
    correo: "",
    telefono: "",
    medical_specialities: [],
    specialities: specialities,
    prioridad: [
      {
        label: 'Alta',
        value: 0,
      },
      {
        label: 'Media',
        value: 1,
      },
      {
        label: 'Baja',
        value: 2,
      },
    ],
    idioma_ingles: false,
  };

  

  const changeEnabled = (item) => {
    setOperation("Edit");
    onSaveData({ id: item.id, disabled: !item["disabled"] });
  };

  const prepareSpecialities = (arraySpec) => {   
    const valueRet =  arraySpec?.map((item)=>{
      return item?.medical_speciality.id
    });
    return valueRet;
  }

  return (
    <div
      className="container-fluid  bg-lightBlue  h-100 w-100 p-0"
      id="body-pd"
    >
      <NavBar_Bootstrap
        onLogout={props.onLogout}
        itemActive={3}
        title={"Especialistas"}
      />
      <Alert_MUI
        color={alertColor}
        title={alertTitle}
        msg={alertMsg}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      
      <TempleteReportes
        export={exportData}
        changeEnabled={changeEnabled}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangeRowPerPage={onChangeRowPerPage}
        onChangePage={onChangePage}
        user={""}
        totalItems={specialists.length}
        onOrderSelect={onOrderSelect}
        onHandleSelectionViewOrders={onHandleSelectionViewOrders}
        columnasName={namesColumn}
        columnsValue={columnsToFill}
        nameReporte="Listado de Especialistas"
        urlToRedirect="/orden/"
        optionsHowToFiltered={namesColumn}
        dataLoad={specialists}
        loading={loading}
        optionsHowToSee={[
          {
            label: "Correo",
            value: "email",
          },
          {
            label: "Nombre",
            value: "firstName",
          },
          { label: "Apellido", value: "lastName" },
          { label: "Especialidad", value: "medical_specialities" },
        ]}
        valuesForColumnColumn={valuesForColumnColumn}
        valueForButton={"Ver "}
        onSearch={onChangeSearch}
        isButtonDisable={true}
        arrayOfStatus={arrayOfStatus}
        buttonsArray={[
          {
            id: "resp-button",
            label: "Notificar",
            functionOnClick: (item)=> OnClickNotify(item),
            className: "button-Secondary",
            icon: <Edit />,
            disabled: () => {
              return false;
            },
          },
          {
            id: "resp-button",
            label: "Editar",
            functionOnClick: onButtonEdit,
            className: "button-Secondary",
            icon: <Edit />,
            disabled: () => {
              return false;
            },
          },
          {
            id: "resp-button",
            label: "Eliminar",
            functionOnClick: (item)=> onClickButtonDelete(item),
            className: "button-Error-Outlined",
            icon: <Delete />,
            disabled: () => {
              return false;
            },
          }
        ]}
        buttonGeneral={{
          value: "Nuevo",
          className: "button-Primary .w-250px",
          onClick: (ev) => {
            ev.preventDefault();
            setorderSelected({});
            setItemSlected({ ...inputData });
            setOperation("New");
            setOpenDialog(true);
          },
        }}
      />
      <CustomDialog
        open={openDialog}
        title="Datos del especialista"
        onCancel={() => {
          setOpenDialog(false);
          setItemSlected({});
        }}
        loading={loadingDialog}
        onSave={onSaveData}
        data={itemSelectedToDialog}
        requiredCampos={["nombre_usuario", "nombre", "apellidos", "correo","telefono"]}
        disabledCampos={["nombre_usuario"]}
        operation={operation}
        inputs_needs_tilde={[          
          {
            key: "telefono",
            traduce: "Teléfono",
          },
        ]}
      />
      <AlertDialog
        color={"info"}
        title={"Confirmación"}
        msg={"Esta seguro de eliminar a " + itemSelectedToDialog.nombre}
        open={visibilityAlertConfirm}
        onSave={()=>onButtonDelete()}
        onClose={() => setvisibilityAlertConfirm(false)}
      />
    </div>
  );
}

let namesColumn = [
  "Historia Clínica",
  "Nombre del Paciente",
  "Especialidad Médica",
  "Nombre del Especialista",
  "Asignado Por",
  "Fecha",
  "Estado",
];

let valuesForColumnColumn = [];

let columnsToFill = [
  {
    id: "username",
    label: "Usuario",
    minWidth: 120,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "firstName",
    label: "Nombre",
    minWidth: 120,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "lastName",
    label: "Apellidos",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Correo",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "medical_specialities",
    label: "Especialidad",
    minWidth: 170,
    align: "center",
    isObject: true,
    isArray: true,
    subitem_show: {
      name: "medical_speciality",
      isArray: false,
      key: "name",
    },
    format: (value) => value.toLocaleString("en-US"),
  },
  
  {
    id: "state",
    label: "Estado",
    minWidth: 170,
    align: "center",
    isObject: true,
    format: (value) => value.toLocaleString("en-US"),
  },
   {
    id: "notification_id",
    label: "En Telegram",
    minWidth: 150,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
];
