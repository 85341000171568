import React, { useState } from "react";
import TempleteReportes from "../../Components/Containers/TempleteReportes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import NavBar_Bootstrap from "../../Components/Components/NavBars/NavBar_Bootstrap";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import AlertDialog from "../../Components/Components/AlertDialog";
import exportFromJSON from "export-from-json";
import { Delete, Edit } from "@mui/icons-material";
import CustomDialog from "../../Components/Components/CustomDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import Alert_MUI from "../../Components/Components/Alert_MUI";
//import UserIcon from "../../Assets/happy-smiling-young-man-3d-260nw-2128644164.png";
import PersonIcon from "@mui/icons-material/Person";
import UserIcon from "../../Assets/happy-smiling-young-man-3d-260nw-2128644164.png";
import New_Table_Customized from "../../Components/Components/New_Table_Customized";
import imageNotFound from "../../Assets/undraw_no_data_re_kwbl.svg";
import Esteroscopio from "../../Assets/esteto.svg";


export default function ReporteAssign(props) {
  let time = new Date();
  let time2 = new Date();

  const {
    report_cases,
    addReportCase,
    editReportCase,
    getAllReportCases,
    removeReportCase,
    getAllSpecialisties,
    specialities,
    getAllSpecialist,
    getAvailableSpecialists,
    specialists,
    asignReport,
    reAsignReport,
    getAssignments,
    assignments,
    getAllMedicalsCenter,
    medicals_centers,
    changeStateAsignIncompleto
  } = React.useContext(GeneralContext);

  const POLLING_INTERVAL = 6000000;

  const [filtrerBy, setfiltrerBy] = useState("");
  const [view, setview] = useState("Todas");
  const [loading, setloading] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setpage] = useState(0);
  const [search, setsearch] = useState("");
  const [orderSelected, setorderSelected] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [itemSelectedToDialog, setItemSlected] = useState({});
  const [operation, setOperation] = useState("New");
  const [alertColor, setAlertColor] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [visibilityAlertConfirm, setvisibilityAlertConfirm] = useState(false);
  const [isManual, setIsMaual] = useState(false);
  const [openDialogSpec, setOpenDialogSpec] = useState(false);
  const [idReport, setIdRep] = useState(-1);
  const [seeDetails, setSeeDetails] = useState(false);
  const [dataForTableReport, setDataForTableR] = useState([]);
  const [opAsign, setOpAsign] = useState(0);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const arrayOfStatusOrders = [
    {
      name: 0,
      color: "info",
      nameTraduced: "Abierto",
    },
    {
      name: 1,
      color: "error",
      nameTraduced: "Expirado",
    },
    {
      name: 2,
      color: "success",
      nameTraduced: "Terminado",
    },
    {
      name: 3,
      color: "warning",
      nameTraduced: "No disponible",
    },
    {
      name: 4,
      color: "warning",
      nameTraduced: "Incompleto",
    },
  ];
  const arrayStatusOptions = [
    {
      value: 0,
      color: "info",
      label: "Abierto",
    },
    {
      value: 1,
      color: "error",
      label: "Expirado",
    },
    {
      value: 2,
      color: "success",
      label: "Terminado",
    },
    {
      value: 3,
      color: "warning",
      label: "No disponible",
    },
    {
      value: 4,
      color: "error",
      label: "Incompleto",
    },
    {
      value: 0,
      color: "warning",
      label: "Todos",
    },
  ]
  const arrayOfStatusAssigns = [
    {
      name: 0,
      color: "info",
      nameTraduced: "Asignado",
    },
    {
      name: 1,
      color: "info",
      nameTraduced: "Aceptado",
    },
    {
      name: 2,
      color: "error",
      nameTraduced: "Rechazado",
    },
    {
      name: 3,
      color: "error",
      nameTraduced: "Expirado",
    },
    {
      name: 4,
      color: "success",
      nameTraduced: "Completado",
    },
    {
      name: 5,
      color: "warning",
      nameTraduced: "Reasignado",
    },
  ];

  const prepareMedicalCenters = (array) => {
    return array?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };
  const prepareSpecial = (array) => {
    return array?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  const [inputData, setInputData] = useState({
    paciente: "",
    edad: "0",
    estudio_realizado: "",
    motivo_estudio: "",
    tiempo_esperado: "0",
    especialidad: [],
    centro_medico: [],    
    asignacion_manual: false,
    idioma_ingles: false,
  });
  
  const onOrderSelect = (id) => {
   
    setorderSelected(id);
    const newCenters = medicals_centers.sort(function (a, b) {
      if (a.value === id.medical_center_id) {
        return -1;
      }
      if (a.value !== id.medical_center_id) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    const new_specialities = specialities.sort(function (a, b) {
      if (a.id === id.medical_speciality_id) {
        return -1;
      }
      if (a.id !== id.medical_speciality_id) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });


    setItemSlected({
      ...itemSelectedToDialog,
      id: id.id,
      paciente: id.patient_name,
      ref_number: id.ref_number,
      edad: id.patient_age.toString(),
      estudio_realizado: id.study_realized,
      motivo_estudio: id.study_reason,
      tiempo_esperado: id.period_duration.toString(),
      especialidad: prepareMedicalCenters(new_specialities),
      centro_medico: prepareMedicalCenters(newCenters),   
        
    });
  };

  const onButtonEdit = async (item) => {
    const newCenters = medicals_centers.sort(function (a, b) {
      if (a.id === item.medical_center_id) {
        return -1;
      }
      if (a.id !== item.medical_center_id) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    const new_specialities = specialities.sort(function (a, b) {
      if (a.id === item.medical_speciality_id) {
        return -1;
      }
      if (a.id !== item.medical_speciality_id) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    setItemSlected({
      id: item.id,
      ref_number: item.ref_number,
      paciente: item.patient_name,
      edad: item.patient_age.toString(),
      estudio_realizado: item.study_realized,
      motivo_estudio: item.study_reason,
      tiempo_esperado: item.period_duration.toString(),
      especialidad: prepareMedicalCenters(new_specialities),
      centro_medico: prepareMedicalCenters(newCenters),
     
    });
   
    setOperation("Edit");
    //this.props.history.push("/orden/" + orderSelected.id);
    setOpenDialog(true);
  };

  const onButtonDelete = async (item) => {
   
    await removeReportCase(orderSelected.id).then((next)=>{
      if(next && next.errorStatus === undefined){
        setvisibilityAlertConfirm(false);
        setAlertColor("success");
        setAlertMsg("Reporte eliminado con éxito");
        setAlertTitle("Operación exitosa");
        setAlertOpen(true);
        refreshData();
      }
      else{
        setAlertColor("error");
        setAlertMsg("Ha aocurrido un error al eliminar el reporte");
        setAlertTitle("Error");
        setAlertOpen(true);
      }
    });
    
  };

  const onButtonView = async (item) => {
   
    const newCenters = medicals_centers.sort(function (a, b) {
      if (a.id === item.medical_center_id) {
        return -1;
      }
      if (a.id !== item.medical_center_id) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    const new_specialities = specialities.sort(function (a, b) {
      if (a.id === item.medical_speciality_id) {
        return -1;
      }
      if (a.id !== item.medical_speciality_id) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    setItemSlected({
      id: item.id,
      ref_number: item.ref_number,
      paciente: item.patient_name,
      edad: item.patient_age.toString(),
      estudio_realizado: item.study_realized,
      motivo_estudio: item.study_reason,
      especialidad: prepareMedicalCenters(new_specialities),
      centro_medico: prepareMedicalCenters(newCenters),
      asignacion_manual: item.assignment_type === 1 ? true : false,
      idioma_ingles: item.english !== undefined ? item.english : false 
    });
    await getAssignments("report_id", item.id);
    setSeeDetails(true);
  };

  const onHandleSelectionViewOrders = (select) => {
    setview(select);
    setloading(true);

    
    if (select === null || select === undefined) {
      select = "Todas";
    }
  };

  React.useEffect(() => {
    refreshData();
  }, [search]);

  const onChangeRowPerPage = (rowsCount) => {
    setrowsPerPage(rowsCount);
  };

  const onChangePage = (pageActually) => {
    setpage(pageActually);
  };

  const onClickButtonDelete = (item) => {
    setItemSlected({
      id: item.id,
     ref_number: item.ref_number,
    });
  
    setvisibilityAlertConfirm(true);
  };

  const exportData = () => {
    const usersToExport = report_cases.map((item) => {
      return {
        Nombre_del_Paciente: item.patient_name,
        Edad_del_Paciente: item.patient_age,
        Motivo_del_estudio: item.study_realized,
        Especialidad_Medica: item.medical_speciality.name,
        Asignacion: item.assignment_type === 1 ? "Manual" : "Automatica",
      };
    });
    const fileName =
      "Listado de Reportes y Asignaciones_" +
      new Date().getDate() +
      "-" +
      new Date().getMonth();
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: usersToExport, fileName, exportType });
  };

  const onChangeSearch = (filter, value) => {
    setfiltrerBy(filter);
    console.log("Search",filter, value);
  
    if (filter === "medical_speciality_id" && value !== "") {
      const founded = specialities.find((item) => item.name.toUpperCase().includes(value.toUpperCase()));
      console.log("Founded",founded);

      if (founded && founded !== -1) {
        setsearch(founded.id);
      }
    } else if(filter === "medical_center_id" && value !== "") {
      const founded = medicals_centers.find((item) => item.name.toUpperCase().includes(value.toUpperCase()));
     
      if (founded && founded !== -1) {
        setsearch(founded.id);
      }
    }
    else setsearch(value);
  };

    const onSaveSpec = async (dataSpec) => {
    setOpenDialogSpec(false);

    let resp;
    if (opAsign === 0)
      resp = await asignReport(idReport, dataSpec ? dataSpec : null);
    else resp = await reAsignReport(itemSelectedToDialog.id, dataSpec);
    if (resp?.errorStatus === undefined) {
      setAlertColor("success");
      setAlertMsg("El reporte fue asignado con éxito");
      setAlertTitle("Operación exitosa");
      setAlertOpen(true);
    }
  };

  const onSaveData = async (data) => {
   
    setLoadingDialog(true);
    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    let regex = /^[0-9]*$/;


    if (data.email && data.email !== "" && !validEmail.test(data.email)) {
      setAlertColor("error");
      setAlertMsg("Introduzca un correo válido");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    }
    else if (operation === "New" && (!data.medical_speciality_id || data.medical_speciality_id.length === 0)) {
      setAlertColor("error");
      setAlertMsg("Introduzca una especialidad");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    }
    else if (operation === "New" && (!data.medical_center_id || data.medical_center_id.length === 0)) {
      setAlertColor("error");
      setAlertMsg("Introduzca un centro médico");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    }
    else if(!regex.test(data.tiempo_esperado) || !regex.test(data.edad)){
      setAlertColor("error");
      setAlertMsg("El tiempo esperado y la edad deben contener solo números");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    }
    else {
      if (operation === "New") {
        const dataToSave = {
          patient_name: data.paciente,
          patient_age: Number(data.edad),
          study_realized: data.estudio_realizado,
          study_reason: data.motivo_estudio,
          medical_speciality_id: data.medical_speciality_id[0],
          assignment_type: data.asignacion_manual ? 1 : 0,
          period_duration: Number(data.tiempo_esperado),
          medical_center_id: data.medical_center_id[0],
          english: data.idioma_ingles,
        };

        const resp = await addReportCase(dataToSave);
       
        if (resp?.errorStatus === undefined) {
          setIdRep(resp.id);
          setOpenDialog(false);
          setorderSelected({});
          setItemSlected({});
          setLoadingDialog(false);
          if (data.asignacion_manual) {
            await getAvailableSpecialists(
              "medical_speciality_id",
              data.medical_speciality_id[0],
              data.idioma_ingles
            ).then((next) => {
              if(next.length > 0){
                setOpAsign(0);
                setOpenDialogSpec(true);
              }
              else{
                setAlertColor("info");
                setAlertMsg("No hay especialistas disponibles");
                setAlertTitle("Información");
                setAlertOpen(true);
              }
              
            });
          } else {
            onSaveSpec();
            setLoadingDialog(false);
            setAlertColor("success");
            setAlertMsg("Reporte añadido con éxito");
            setAlertTitle("Operación exitosa");
            setAlertOpen(true);
            refreshData();
          }
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409)
            setAlertMsg(
              "Ha ocurrido un conflicto, revise su correo y nombre de usuario"
            );
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
          setLoadingDialog(false);
        }
      } else {
        const resp = await editReportCase(
          {
            patient_name: data.paciente,
            patient_age: Number(data.edad),
            study_realized: data.estudio_realizado,
            study_reason: data.motivo_estudio,
            medical_speciality_id: data.medical_speciality_id ? data.medical_speciality_id[0] : data.especialidad[0].id,
            medical_center_id: data.medical_center_id ? data.medical_center_id[0] : data.centro_medico[0].id,
          },
          orderSelected.id
        );
       

        if (resp?.errorStatus === undefined) {
          setAlertColor("success");
          setAlertMsg("Reporte editado con éxito");
          setAlertTitle("Operación exitosa");
          setAlertOpen(true);
          setOpenDialog(false);
          refreshData();
          setorderSelected({});
          setItemSlected({});
          setLoadingDialog(false);
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409)
            setAlertMsg(
              "Ha ocurrido un conflicto, revise su correo y nombre de usuario"
            );
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
          setLoadingDialog(false);
        }
      }
    }
  };

  const searchAssignByReport = (idReport) => {
    return assignments?.find((item) => item.report_id === idReport);
  };

  const refreshData = async () => {
    setloading(true);
    let dataToShow = [];
    
    await getAllSpecialisties().then((next)=>{
      getAllMedicalsCenter().then((next2)=>{
        setInputData({
          paciente: "",
          edad: "0",
          estudio_realizado: "",
          motivo_estudio: "",
          tiempo_esperado: "0",
          especialidad: prepareSpecial(next),
          centro_medico: prepareMedicalCenters(next2),    
          asignacion_manual: false,
          idioma_ingles: false,
         });
      })
      
    });
    await getAllSpecialist();
    await getAssignments("state", 1);
    await getAllReportCases(filtrerBy, search).then((next) => {      
    
      dataToShow = next.map((item) => {
        const timeOfAssigment = new Date(searchAssignByReport(item.id)?.assignment_date).getTime();
        const timeLeft = timeOfAssigment + item.period_duration * 60000;        

        return {
          ...item,
          expiration_time:
            Number.isNaN(timeLeft) || timeLeft < 0
              ? 0
              : timeOfAssigment === 0 ? 0 : new Date().setTime(timeLeft),
        };
      });
      
      setDataForTableR(dataToShow);
      setloading(false);
    });
  };

  return (
    <div
      className="container-fluid  bg-lightBlue  h-100 w-100 p-0"
      id="body-pd"
    >
      <NavBar_Bootstrap
        onLogout={props.onLogout}
        itemActive={2}
        title={"Reportes de Casos"}
      />

      <Alert_MUI
        color={alertColor}
        title={alertTitle}
        msg={alertMsg}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <AlertDialog
       id="confirmDialog"
        color={"info"}
        title={"Confirmación"}
        msg={"Esta seguro de eliminar el reporte " + itemSelectedToDialog.ref_number + "?"}
        open={visibilityAlertConfirm}
        onSave={onButtonDelete}
        onClose={() => setvisibilityAlertConfirm(false)}/>

      {seeDetails ? (
        <div className="row h-100 pb-5">
          <div className="col-12 col-lg-11 col-sm-12 ms-auto mt-5 pt-3">
            <div
              className="row bg-transparent justify-content-around d-flex
           align-content-around h-100"
            >
              {assignments?.length !== 0 ? (
                <div className="col-lg-3  col-sm-12 col-12 p-2 pt-4 pb-0 ">
                  <div className="card shadow  w-100 rounded-15 h-100">
                    <div className="card-title mt-3">
                      <div className="circle ">
                      <img src={Esteroscopio} height="95%" width="95%" className="m-auto" />
                      </div>
                    </div>
                    <div className="card-header">
                      <div className="w-100 text-center">
                        <h4>#{itemSelectedToDialog.ref_number}</h4>
                      </div>
                      <div className="w-100 row mt-3">
                        <h6 className="text-secondary col-6">Paciente:</h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.paciente}
                        </h6>
                      </div>
                      <div className="w-100 row  mt-1">
                        <h6 className="text-secondary col-6">Edad:</h6>
                        <h6 className="col-6">{itemSelectedToDialog.edad}</h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">Centro Médico:</h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.centro_medico[0].label}
                        </h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">Especialidad:</h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.especialidad[0].label}
                        </h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">
                          Motivo del estudio:
                        </h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.motivo_estudio}
                        </h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">
                          Causa de visita:
                        </h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.estudio_realizado}
                        </h6>
                      </div>
                    </div>

                    <div className="card-footer mt-2 d-flex justify-content-center">
                      <button
                        className="button-Primary"
                        onClick={() => setSeeDetails(false)}
                      >
                        Regresar
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-5  col-sm-12 col-12 p-2 pt-4 pb-0 ">
                  <div className="card shadow  w-100 rounded-15 h-100">
                    <div className="card-title mt-3">
                      <div className="circle ">
                        <img
                          src={UserIcon}
                          height="100%"
                          width="100%"
                          className="m-auto"
                        />
                      </div>
                    </div>
                    <div className="card-header">
                      <div className="w-100 text-center">
                        <h4>#{itemSelectedToDialog.ref_number}</h4>
                      </div>
                      <div className="w-100 row mt-3">
                        <h6 className="text-secondary col-6">Paciente:</h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.paciente}
                        </h6>
                      </div>
                      <div className="w-100 row  mt-1">
                        <h6 className="text-secondary col-6">Edad:</h6>
                        <h6 className="col-6">{itemSelectedToDialog.edad}</h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">Centro Médico:</h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.centro_medico[0].label}
                        </h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">Especialidad:</h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.especialidad[0].label}
                        </h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">
                          Motivo del estudio:
                        </h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.motivo_estudio}
                        </h6>
                      </div>
                      <div className="w-100 row mt-1">
                        <h6 className="text-secondary col-6">
                          Causa de visita:
                        </h6>
                        <h6 className="col-6">
                          {itemSelectedToDialog.estudio_realizado}
                        </h6>
                      </div>
                    </div>

                    <div className="card-footer mt-2 d-flex justify-content-center">
                      <button
                        className="button-Primary"
                        onClick={() => setSeeDetails(false)}
                      >
                        Regresar
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {assignments?.length !== 0 ? (
                <div className="col-lg-9 col-sm-12 col-12 p-2 pt-4 pb-0 ">
                  <div className="card shadow w-100 rounded-15 h-100 p-2">
                    <New_Table_Customized
                      columns={columnsAssigns}
                      dataLoad={assignments}
                      arrayOfStatus={arrayOfStatusAssigns}
                      onElementSelected={() => {}}
                      page={0}
                      rowsPerPage={100}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100 h-100">
          <TempleteReportes
            refresh={refreshData}
            export={exportData}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowPerPage={onChangeRowPerPage}
            onChangePage={onChangePage}
            user={""}
            totalItems={report_cases.length}
            onOrderSelect={onOrderSelect}
            onHandleSelectionViewOrders={onHandleSelectionViewOrders}
            columnasName={namesColumn}
            columnsValue={columnsToFill}
            nameReporte="Listado de Reportes de Casos"
            urlToRedirect="/orden/"
            optionsHowToFiltered={namesColumn}
            dataLoad={dataForTableReport}
            loading={loading}
            optionsHowToSee={[
              {
                label: "Especialidad",
                value: "medical_speciality_id",
              },
              {
                label: "Paciente",
                value: "patient_name",
              },
                {
                label: "Identificador",
                value: "ref_number",
              },
              {
                label: "Estado",
                value: "state",
              },
            ]}
            valuesForColumnColumn={valuesForColumnColumn}
            valueForButton={"Ver "}
            onSearch={onChangeSearch}
            statusOptions={arrayStatusOptions}
            arrayOfStatus={arrayOfStatusOrders}
            isButtonDisable={true}
            buttonsArray={[
              {
                id: "resp-button",
                label: "Ver",
                functionOnClick: onButtonView,
                className: "button-Secondary",
                icon: <Edit />,
                disabled: () => {
                  return false;
                },
              },             
              {
                id: "resp-button",
                label: "Reasignar",
                functionOnClick: async (item) => {
                
                  await getAvailableSpecialists(
                    "medical_speciality_id",
                    item?.medical_speciality.id,
                    item?.english
                  ).then((next) => {
                    if(next.length > 0){
                      setOpAsign(1);
                      setOpenDialogSpec(true);
                    }
                    else{
                      setAlertColor("info");
                      setAlertMsg("No hay especialistas disponibles");
                      setAlertTitle("Informacion");
                      setAlertOpen(true);
                    }
                    
                  });
                 
                },
                className: "button-Secondary",
                icon: <Edit />,
                disabled: () => {
                  return false;
                },
              },
              {
                id: "resp-button",
                label: "Editar",
                functionOnClick: onButtonEdit,
                className: "button-Secondary",
                icon: <Edit />,
                disabled: () => {
                  return false;
                },
                notInState: [1,2],
              },
              {
                id: "resp-button",
                label: "Eliminar",
                functionOnClick: onClickButtonDelete,
                className: "button-Error-Outlined",
                icon: <Delete />,
                disabled: () => {
                  return false;
                },
              },
            ]}
            buttonGeneral={{
              value: "Nuevo",
              className: "button-Primary .w-250px",
              onClick: (ev) => {
                ev.preventDefault();
                setorderSelected({});
                setItemSlected({ ...inputData });
                setOperation("New");
                setOpenDialog(true);
              },
              disabled: specialists === undefined || inputData.especialidad?.length === 0 || medicals_centers === undefined || inputData.centro_medico?.length === 0
            }}
          />
          <CustomDialog
            open={openDialog}
            title="Datos del reporte"
            onCancel={() => {
              setOpenDialog(false);
              setItemSlected({});
            }}
            loading={loadingDialog}
            onSave={onSaveData}
            data={itemSelectedToDialog}
            requiredCampos={[
              "paciente",
              "edad",
              "estudio_realizado",
              "motivo_estudio",
              "especialidad",
              "centro_medico"
            ]}
            inputs_needs_tilde={[
              {
                key: "centro_medico",
                traduce: "Centro Médico",
              },
              {
                key: "asignacion_manual",
                traduce: "Asignación Manual",
              },
              {
                key: "tiempo_esperado",
                traduce: "Tiempo Esperado (minutos)",
              },
            ]}
            disabledCampos={["asignacion_manual",'tiempo_esperado']}
            operation={operation}

          />
          <Dialog
            onClose={() => setOpenDialogSpec(false)}
            open={openDialogSpec}
          >
            <DialogTitle>Seleccione un especialista </DialogTitle>
            <Grid
              className="p-2"
              style={{ justifyContent: "space-around" }}
              container
            >
              {
              specialists.map((doc) => (
                <Grid
                  xs={12}
                  md={6}
                  lg={specialists.length > 1 ? 6 : 12}
                  key={doc}
                  style={{ padding: "5px" }}
                >
                  <div className="card shadow  w-100 rounded-15 h-100">
                    <div className="card-title mt-3">
                      <div className="circle">
                        <img
                          src={UserIcon}
                          height="100%"
                          width="100%"
                          className="m-auto"
                        />
                      </div>
                    </div>
                    <div className="card-header h-100">
                      <div className="w-100 text-center">
                        <h4>{doc.firstName + doc.lastName}</h4>
                      </div>
                      <div className="w-100 text-center">
                        <h6 className="text-secondary">{doc.username}</h6>
                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-center">
                      <input
                        onClick={() => onSaveSpec(doc.id)}
                        type="submit"
                        id="loginsub"
                        value="Asignar"
                        className="button-Primary w-75 "
                      />
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Dialog>
        </div>
      )}
    </div>
  );
}

let namesColumn = [
  "Historia Clínica",
  "Nombre del Paciente",
  "Especialidad Médica",
  "Nombre del Especialista",
  "Asignado Por",
  "Fecha",
  "Estado",
];

let valuesForColumnColumn = [];

let columnsAssigns = [
  {
    id: "assignment_date",
    label: "Fecha Asignacion",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "expiration_time",
    label: "Fecha Expiracion",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "specialist",
    label: "Especialista",
    minWidth: 170,
    align: "center",
    isObject: true,
    subitem_show: {
      name: "firstName",
      isArray: false,
      key: "firstName",
    },
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state",
    label: "Estado",
    minWidth: 170,
    align: "center",
    isObject: true,
    format: (value) => value.toLocaleString("en-US"),
  },
];

let columnsToFill = [
  {
    id: "ref_number",
    label: "Identificador",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "patient_name",
    label: "Nombre del Paciente",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "patient_age",
    label: "Edad del Paciente",
    minWidth: 120,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "medical_speciality",
    label: "Especialidad Médica",
    minWidth: 120,
    align: "center",
    isObject: true,
    format: (value) => value.toLocaleString("en-US"),
    isArray: false,
    subitem_show: {
      name: "name",
      isArray: false,
      key: "name",
    },
  },

  /*
  {
    id: "assigned_by",
    label: "Asignado Por",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },*/
  {
    id: "study_reason",
    label: "Razón de estudio",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state",
    label: "Estado",
    minWidth: 170,
    align: "center",
    isObject: true,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "timer",
    label: "Temporizador",
    minWidth: 170,
    align: "center",
    isObject: true,
    format: (value) => value.toLocaleString("en-US"),
  },
];
