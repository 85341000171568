import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./Assets/scss/dashlite.scss";
import reportWebVitals from './reportWebVitals';
import AuthState from "./Contexts/auth-context/AuthState";
import GeneralState from "./Contexts/general-context/GeneralState";
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle";
import './General.css'

const Error404Modern = React.lazy(() => import("./Components/Containers/404-modern"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
  <AuthState>
    <GeneralState>
     <App></App>
    </GeneralState>
  </AuthState>
</React.Fragment>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
