import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import "../../General.css";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCheck,
  faCopy,
  faWindowClose,
  faWallet,
  faCross,
  faEdit,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import Timer from "./Timer";
import Validaciones from "../../Services/Utils/Validaciones";

export default function New_Table_Customized(props) {
  const {
    columns,
    dataLoad,
    idsCampoTypeObject,
    onElementSelected,
    buttonsArray,
    changeEnabled,
    arrayOfStatus,
    page,
    rowsPerPage,
    ...other
  } = props;

  const [selectedRow, setSelectedRow] = React.useState([]);

  const history = useNavigate();

  const handleClickRow = (event, idSelection) => {
    setSelectedRow(idSelection);
    if (onElementSelected !== null) {
      onElementSelected(idSelection);
    }
  };

  const isStateOf = (objectToCompare, col, row) => {
    const forValue = objectToCompare.for;

    return (
      objectToCompare.id === col.id &&
      objectToCompare.value === false &&
      row[forValue] === false
    );
  };

  const getFecha = (fecha) => {
    return new Date(fecha.slice(6, 10), fecha.slice(3, 5), fecha.slice(0, 2));
  };

  return (
    <Paper sx={{ width: "100%", padding: "0", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table aria-label="sticky table">
          <TableHead className="color-mediumGray">
            <TableRow className="color-mediumGray h-60-px">
              {columns?.map((column) => (
                <TableCell
                  className="color-mediumGray p-0"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label.toUpperCase()}
                </TableCell>
              ))}
              {buttonsArray?.map((button) => (
                <TableCell className="p-0" align="center"></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoad
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                let cont = 2;

                let productType = "";
                let count = 0;

                return (
                  <TableRow
                    className="h-60-px"
                    hover
                    aria-checked={selectedRow === row}
                    selected={selectedRow === row}
                    onClick={(event) => handleClickRow(event, row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    {columns.map((column) => {
                      let value = "";
                      let colorBooleans = "";
                      let indexFound = 0;
                      if (column.id === "timer") {
                        const time = new Date();
                        value =
                          row["expiration_time"] !== 0 ? (
                            <Timer
                              expiryTimestamp={
                                row["expiration_time"] !== null
                                  ? row["expiration_time"]
                                  : time
                              }
                            ></Timer>
                          ) : (
                            "----"
                          );
                      } else if (
                        column.isObject === true &&
                        column.subitem_show
                      ) {
                        if (column.isArray) {
                          row[column.id].map(
                            (item) =>
                              (value =
                                value +
                                " " +
                                item[column.subitem_show.name][
                                  column.subitem_show.key
                                ])
                          );
                        } else {
                          value = row[column.id][column.subitem_show.name];
                        }
                      } else if (
                        column.isObject === true &&
                        column.id.slice(0, 5) !== "state" &&
                        column.id.slice(0, 4) !== "prod"
                      ) {
                        value = row[column.id][idsCampoTypeObject[count]];
                     
                        count++;
                      } else if(column.id === 'created_at' || column.id === 'expiration_time' || column.id === 'assignment_date'){
                        value = Validaciones.convertDateToSpanishDate(row[column.id]);
                      }
                       else if (column.id === "order_id") {
                        value = (
                          <Button
                            className="button-Primary"
                            onClick={() => history("/orden/" + row.order_id)}
                            variant="contained"
                            id={row.order_id}
                            endIcon={<ChevronRightIcon />}
                          >
                            Ver mas
                          </Button>
                        );
                      } else if (
                        column.isObject === false &&
                        (row[column.id] === false || row[column.id] === true)
                      ) {
                        value = iconsBools[Number(row[column.id])];
                      } else if(column.id === "notification_id"){
                        const val = row[column.id] === null ? false : true;
                        value = iconsBools[Number(val)];
                      }
                      else if (row[column.id] === null) {
                        value = "-----";
                      } else if (
                        column.isObject === true &&
                        column.id === "state"
                      ) {
                        const itemFound = arrayOfStatus.find(
                          (it) => it.name === row["state"]
                        );
                        if (
                          itemFound !== -1 &&
                          itemFound !== null &&
                          itemFound !== undefined
                        ) {
                          value = itemFound.nameTraduced;
                          colorBooleans = itemFound.color;
                        }
                      } else if (
                        column.isObject === true &&
                        column.id !== "stateOrder" &&
                        column.id.slice(0, 5) === "state"
                      ) {
                        statesToObjects.map((objectToCompare) => {
                          const forValue = objectToCompare.for;

                          if (
                            objectToCompare.id === column.id &&
                            row[forValue] !== null &&
                            (row[forValue].length > 0 || row[forValue] === true)
                          ) {
                            if (forValue === "expiration_date") {
                              const today = new Date();
                              const dayExpire = getFecha(
                                row["expiration_date"]
                              );
                            
                              const diferencia =
                                (dayExpire.getTime() - today.getTime()) /
                                86400000;
                              Math.round(diferencia);
                            
                              if (diferencia > 30) {
                                value = value = "Nuevo";
                                colorBooleans = "success";
                              } else if (diferencia < 30 && diferencia > 1) {
                                value = "Por vencer en " + diferencia + " dias";
                                colorBooleans = "danger";
                              } else if (diferencia < 50) {
                                value = value = "Vencido";
                                colorBooleans = "error";
                              }
                            } else {
                            
                              value = "Completado";
                              colorBooleans = "success";
                          
                            }
                          } else if (
                            objectToCompare.id === column.id &&
                            (row[forValue] === null || row[forValue] === false)
                          ) {
                            value = "Pendiente";
                            colorBooleans = "warning";
                          }
                        });
                      } else if (
                        column.isObject === true &&
                        column.id === "prod"
                      ) {
                        const found = productsObjects.find(
                          (elem) => elem.id === row["productable_type"]
                        );
                       
                        value = found.show;
                      } else if (
                        column.isObject === true &&
                        column.id === "prodName"
                      ) {
                        const typeProducts = row["productable_type"];                       
                        value = row[typeProducts]["name"];
                     
                      } else if (column.id === "log_type") {
                        const typeFound = idsCampoTypeObject.find(
                          (elem) => elem.name === row[column.id]
                        );

                        value = (
                          <div
                            style={{
                              background: colorArray[typeFound.id],
                            }}
                            className={
                              "font-size-11 rounded-15 text-center p-1  "
                            }
                          >
                            <h6 className="">{row[column.id]}</h6>
                          </div>
                        );
                      } else {
                        value = row[column.id];
                      }

                      return column.isObject === true &&
                        column.id.slice(0, 5) === "state" ? (
                        <TableCell
                          className="p-0"
                          key={row.id + column.id + row[column.id]}
                          align={column.align}
                        >
                          <div
                            className={
                              "font-size-11 rounded-2 text-center p-1 ms-auto me-auto  ChipMine-" +
                              colorBooleans
                            }
                          >
                            <h6 className="">{value}</h6>
                          </div>
                        </TableCell>
                      ) : column.id === "enabled" ||
                        column.id === "disabled" ? (
                        <TableCell
                          className="p-0"
                          key={row.id + row[column.id]}
                          align={column.align}
                        >
                          {row[column.id] ? (
                            <CheckIcon
                              size={"2x"}
                              sx={{ color: "#55C2C3" }}
                              
                            />
                          ) : (
                            <CloseIcon
                              size={"2x"}
                              sx={{ color: "#ea1616" }}
                              onClick={() => changeEnabled(row)}
                            />
                          )}
                        </TableCell>
                      ) : (
                        <TableCell
                          className="p-0"
                          key={row.id + column.id + row[column.id]}
                          align={column.align}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                    {buttonsArray !== null &&
                    buttonsArray?.length > 0 &&
                    buttonsArray !== undefined ? (
                      buttonsArray.map((button) =>
                        button.inState !== undefined ? (
                          button.inState === row["state"] ? (
                            <TableCell
                              className="p-0"
                              key={button.label}
                              align="center"
                            >
                              <Button
                                variant="contained"
                                onClick={(event) => {
                                  handleClickRow(event, row);
                                  button.functionOnClick(row);
                                }}
                                endIcon={button.icon}
                                className={button.className}
                                disabled={button.disabled(row)}
                              >
                                {button.label}
                              </Button>
                            </TableCell>
                          ) : null
                        ) : button.notInState !== undefined ?
                        !button.notInState.includes(row["state"]) ? (
                          <TableCell
                            className="p-0"
                            key={button.label}
                            align="center"
                          >
                            <Button
                              variant="contained"
                              onClick={(event) => {
                                handleClickRow(event, row);
                                button.functionOnClick(row);
                              }}
                              endIcon={button.icon}
                              className={button.className}
                              disabled={button.disabled(row)}
                            >
                              {button.label}
                            </Button>
                          </TableCell>
                        ) :  <TableCell
                        className="p-0"
                        key={button.label}
                        align="center"
                      >
                        <Button
                          variant="contained"
                          onClick={(event) => {
                            handleClickRow(event, row);
                            button.functionOnClick(row);
                          }}
                          endIcon={button.icon}
                          className={button.className}
                          disabled={true}
                        >
                          {button.label}
                        </Button>
                      </TableCell>
                        :
                        (
                          <TableCell
                            className="p-0"
                            key={button.label}
                            align="center"
                          >
                            <Button
                              variant="contained"
                              onClick={(event) => {
                                handleClickRow(event, row);
                                button.functionOnClick(row);
                              }}
                              endIcon={button.icon}
                              className={button.className}
                              disabled={button.disabled(row)}
                            >
                              {button.label}
                            </Button>
                          </TableCell>
                        )
                      )
                    ) : (
                      <div />
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

New_Table_Customized.propTypes = {
  idUser: PropTypes.number,
  columns: PropTypes.array.isRequired,
  dataLoad: PropTypes.array.isRequired,
  idsCampoTypeObject: PropTypes.array.isRequired,
  buttonsArray: PropTypes.array.isRequired,
};

const iconsBools = [
  <CloseIcon size={"2x"} sx={{ color: "#ea1616" }} />,
  <CheckIcon size={"2x"} sx={{ color: "#55C2C3" }} />,
];

const productsObjects = [
  {
    type: "productable_type",
    id: "package",
    name: "Paquete",
    show: (
      <Box sx={{ display: "flex" }}>
        <IconButton
          className="color-info"
          aria-label="open drawer"
          edge="start"
          sx={{
            mr: 1,
          }}
        >
          <ShoppingCartIcon />
        </IconButton>
        <Typography variant="h6" className="color-info" noWrap component="div">
          Paquete de inversión
        </Typography>
      </Box>
    ),
  },
  {
    type: "productable_type",
    id: "membership",
    name: "Membresía",
    show: (
      <Box sx={{ display: "flex" }}>
        <IconButton
          color="secondary"
          aria-label="open drawer"
          edge="start"
          sx={{
            mr: 1,
          }}
        >
          <CardMembershipIcon />
        </IconButton>
        <Typography variant="h6" color="secondary" noWrap component="div">
          Membresía
        </Typography>
      </Box>
    ),
  },
];

const statesToObjects = [
  {
    id: "stateOrder",
    value: "date",
    stateName: "Completado",
    color: "success",
    for: "status",
  },
  {
    id: "stateOrder",
    value: null,
    stateName: "Pendiente",
    color: "warning",
    for: "status",
  },
  {
    id: "stateWithDrawl",
    value: true,
    stateName: "Completado",
    color: "success",
    for: "completed",
  },
  {
    id: "stateWithDrawl",
    value: true,
    stateName: "Completado",
    color: "success",
    for: "completed",
  },
  {
    id: "stateProducts",
    value: true,
    stateName: "Vencido",
    color: "error",
    for: "expiration_date",
  },
  {
    id: "stateProducts",
    value: true,
    stateName: "Por vencer",
    color: "warning",
    for: "expiration_date",
  },
  {
    id: "stateProducts",
    value: true,
    stateName: "Nuevo",
    color: "success",
    for: "expiration_date",
  },
  {
    id: "stateProfits",
    value: true,
    stateName: "Nuevo",
    color: "success",
    for: "expiration_date",
  },
];

const colorArray = [
  "#EF6633",
  "#EFB399",
  "#EF33FF",
  "#EFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
];
