import React, { useState } from "react";
import TempleteReportes from "../../Components/Containers/TempleteReportes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import { Delete, Edit } from "@mui/icons-material";
import CustomDialog from "../../Components/Components/CustomDialog";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import NavBar_Bootstrap from "../../Components/Components/NavBars/NavBar_Bootstrap";
import AlertDialog from "../../Components/Components/AlertDialog";
import exportFromJSON from "export-from-json";

export default function SpecialitiesList(props) {
  const {
    getAllSpecialisties,
        specialities,
        addSpeciality,
        editSpeciality,
        removeSpeciality
  } = React.useContext(GeneralContext);
  const [filtrerBy, setfiltrerBy] = useState("");  
  const [view, setview] = useState("Todas");
  const [loading, setloading] = useState(false);  
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setpage] = useState(0);
  const [search, setsearch] = useState(""); 
  const [orderSelected, setorderSelected] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [itemSelectedToDialog, setItemSlected] = useState({});
  const [operation, setOperation] = useState("New");
  const [alertColor, setAlertColor] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [visibilityAlertConfirm, setvisibilityAlertConfirm] = useState(false);

  const onOrderSelect = (id) => {
  
    setorderSelected(id);
    setItemSlected({
      id: id.id,
      nombre: id.name,     
    });
  };

  const onButtonEdit = async (item) => {
    setItemSlected({
        id: item.id,
        nombre: item.name ? item.name : "",        
      });
     
      setOperation("Edit");
      //this.props.history.push("/orden/" + orderSelected.id);
      setOpenDialog(true);
  };

  const onButtonDelete = async (item) => {
   
    const resp = await removeSpeciality(orderSelected.id);
    if (resp?.errorStatus === undefined) {
    setvisibilityAlertConfirm(false);
    setAlertColor("success");
    setAlertMsg("Especialidad eliminada con éxito");
    setAlertTitle("Operación exitosa");
    setAlertOpen(true);
    await getAllSpecialisties();
    }
    else{
        setAlertColor("error");
        if (resp?.errorStatus === 409)
          setAlertMsg(
            "Ha ocurrido un conflicto, no puede eliminar una especialidad en uso"
          );
        else setAlertMsg("Ha ocurrido un error");
        setAlertTitle("Error");
        setAlertOpen(true);
    }
    
  };

  const onHandleSelectionViewOrders = (select) => {
    setview(select);
    setloading(true);

    if (select === null || select === undefined) {
      select = "Todas";
    }

    this.loadInfoReporte(select, rowsPerPage, page, search);
  };

  React.useEffect(() => {
    getAllSpecialisties();
  }, []);

 

  const onChangeRowPerPage = (rowsCount) => {
    setrowsPerPage(rowsCount);
    loadInfoReporte(view, rowsCount, page, search);
  };

  const onChangePage = (pageActually) => {
    setpage(pageActually);
    
    loadInfoReporte(view, rowsPerPage, pageActually, search);
  };

  const loadInfoReporte = async (selection, rowsPerPage, pageNew, search) => {
    setloading(false);
    
    await getAllSpecialisties();
    
  };

  const onChangeFiltrered = (ev) => {
    setfiltrerBy(ev.target.value);
    setloading(true);
  };

  const isDisabled = (row) => {
    if (row.state === "Terminado") return true;
    else return false;
  };

  const onChangeSearch = async (filter, value) => {
    
    setsearch(value);
    setfiltrerBy(filter);
    await getAllSpecialisties(filter, value);
  };

  const onSaveData = async (data) => {
   

    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (data.email && data.email !== "" && !validEmail.test(data.email)) {
      setAlertColor("error");
      setAlertMsg("Introduzca un correo válido");
      setAlertTitle("Error");
      setAlertOpen(true);
    } else {
      if (operation === "New") {  
        const dataToSave = {
          name: data.nombre,         
        };
        const resp = await addSpeciality(dataToSave);

        if (resp?.errorStatus === undefined) {
          setAlertColor("success");
          setAlertMsg("Especialidad añadida con éxito");
          setAlertTitle("Operación exitosa");
          setAlertOpen(true);
          setOpenDialog(false);
          loadInfoReporte("Todas", 10, 0, "");
          setorderSelected({});
          setItemSlected({});
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409)
            setAlertMsg(
              "Ha ocurrido un conflicto, no deben existir especialidades repetidas"
            );
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
        }
      } else {        
        const resp = await editSpeciality(
          {
            name: data.nombre,          
          },
          orderSelected.id
        );
       
        if (resp?.errorStatus === undefined) {
          setAlertColor("success");
          setAlertMsg("Especialidad editada con éxito");
          setAlertTitle("Operación exitosa");
          setAlertOpen(true);
          setOpenDialog(false);
          loadInfoReporte("Todas", 10, 0, "");
          setorderSelected({});
          setItemSlected({});
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409)
            setAlertMsg(
              "Ha ocurrido un conflicto, no deben existir especialidades repetidas"
            );
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
        }
      }
    }
  };

  const inputData = {
   nombre: "",    
  };

 
  const changeEnabled = (item) => {
    setOperation("Edit");
    onSaveData({ id: item.id, enabled: !item["enabled"] });
  };

  const onClickButtonDelete = (item) => {
    setItemSlected({
      id: item.id,
      name: item.name,      
    });
    setvisibilityAlertConfirm(true);
  };

  const exportData = () => {
    const usersToExport = specialities.map((item) => {
      return {
        Especialidad: item.name,       
      };
    });
    const fileName = "Especialidades";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: usersToExport, fileName, exportType });
  };

  return (
    <div className="container-fluid bg-lightBlue  h-100 w-100 p-0" id="body-pd">
      <Backdrop
        className="glass-panel-loading"
        sx={{ color: "#ffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar_Bootstrap
        userAuth={props.user}
        itemActive={6}
        title={"Especialidades"}
        onLogout={props.onLogout}
      />
      <AlertDialog
        color={"info"}
        title={"Confirmación"}
        msg={"Esta seguro de eliminar esa especialidad?" }
        open={visibilityAlertConfirm}
        onSave={onButtonDelete}
        onClose={() => setvisibilityAlertConfirm(false)}
      />
      <Alert_MUI
        color={alertColor}
        title={alertTitle}
        msg={alertMsg}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <TempleteReportes
        changeEnabled={changeEnabled}
        page={page}
        export={exportData}
        rowsPerPage={rowsPerPage}
        onChangeRowPerPage={onChangeRowPerPage}
        onChangePage={onChangePage}
        user={""}
        totalItems={specialities.length}
        onOrderSelect={onOrderSelect}
        onHandleSelectionViewOrders={onHandleSelectionViewOrders}
        columnasName={namesColumn}
        columnsValue={columnsToFill}
        nameReporte="Listado de especialidades"
        urlToRedirect="/orden/"
        optionsHowToFiltered={namesColumn}
        dataLoad={specialities}
        optionsHowToSee={[
          {
            value: "name",
            label: "Especialidad",
          },
        ]}
        valuesForColumnColumn={valuesForColumnColumn}
        valueForButton={"Ver "}
        onSearch={onChangeSearch}
        isButtonDisable={true}
        buttonsArray={[
          {
            id: "resp-button",
            label: "Editar",
            functionOnClick: onButtonEdit,
            className: "button-Secondary",
            icon: <Edit />,
            disabled: () => {
              return false;
            },
          },
          {
            id: "resp-button",
            label: "Eliminar",
            functionOnClick: onClickButtonDelete,
            className: "button-Error-Outlined",
            icon: <Delete />,
            disabled: () => {
              return false;
            },
          },
        ]}
        buttonGeneral={{
          value: "Nuevo",
          className: "button-Primary .w-250px",
          onClick: (ev) => {
            ev.preventDefault();
            setorderSelected({});
            setItemSlected({ ...inputData });
            setOperation("New");
            setOpenDialog(true);
          },
        }}
      />
      <CustomDialog
        open={openDialog}
        title="Datos de la especialidad"
        onCancel={() => {
          setOpenDialog(false);
          setItemSlected({});
        }}
        onSave={onSaveData}
        data={itemSelectedToDialog}
        requiredCampos={["nombre"]}
        disabledCampos={["nombre_de_usuario"]}
        operation={operation}
      />
    </div>
  );
}

let namesColumn = [
  "Historia Clínica",
  "Nombre del Paciente",
  "Especialidad Médica",
  "Nombre del Especialista",
  "Asignado Por",
  "Fecha",
  "Estado",
];

let valuesForColumnColumn = [];

let columnsToFill = [
    {
    id: "name",
    label: "Especialidad",
    minWidth: 120,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },

];
