import PropTypes from "prop-types";
import axios from "axios";
import React from "react";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../../Contexts/auth-context/AuthContext";
import Validaciones from "../../Services/Utils/Validaciones";
import LoginImage from "../../Assets/undraw_medicine_b-1-ol.svg";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3c78bc",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#3c78bc",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid rgb(169, 169, 169)",
    },
    "&:hover fieldset": {
      borderColor: "#3c78bc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3c78bc",
    },
  },
});

export default function Login(props) {
  const { login } = React.useContext(AuthContext);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [textButton, setTextButton] = React.useState("Iniciar Sesión");
  const [twoFactor, setTwoFactor] = React.useState("");
  const [no_need2fa, setNo_need2fa] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {}, []);
  const history = useNavigate();

  //const history = useHistory();

  const handleSuccessFullAuth = (data) => {
    props.handleLoggin(data);
    //history.push("/");
  };
  const onLogin = async (ev) => {
    ev.preventDefault();
    setTextButton("Iniciando...");
    Validaciones.deshabilitarBotones("button-Primary");

    const res = await login(username, password);
    if (res?.errorStatus === undefined) {
      localStorage.setItem("username", username);
      props.onLogin().then((result)=> {
        history("/profile");
        setTimeout(() => Validaciones.habilitarBotones("button-Primary"), 1000);
      }
      );
     
    } else {
      if (res.errorStatus === 401) setError("Credenciales inválidos");
      else if (res.errorStatus === 400 && res.errorMsg.includes("disabled"))
        setError(
          "Cuenta deshabilitada, por favor, póngase en contacto con un administrador"
        );
      setIsError(true);
      setTextButton("Iniciar Sesión");
      setTimeout(() => Validaciones.habilitarBotones("button-Primary"), 1000);
    }
  };
  return (
    <div className="w-100 h-100 position-absolute d-flex justify-content-center align-content-center align-items-center">
      <div className="col-lg-9 col-sm-12 col-12 justify-content-center align-content-center d-flex ">
        <div className="row glass-panel  shadow rounded-15 m-auto justify-content-center align-content-center d-flex">
          <div className="col-lg-6 col-sm-9 col-9  justify-content-center align-content-center d-flex text-center">
            <div className="card border-none text-center bg-transparent ms-auto me-auto">
              <img src={LoginImage} height="85%" width={"85%"} />
            </div>
          </div>
          <div className="col-lg-5 col-sm-12 col-12 m-2 mb-1 mt-0">
            <div className="card bg-transparent border-none text-end">
              <div className="card-header bg-transparent p-0 border-none ms-auto me-auto justify-content-center d-flex align-content-center">
                <div className="card m-auto text-center bg-transparent border-none">
                  <div className="card-header m-0 text-center bg-transparent border-none"></div>
                  <div className="card-body p-0 m-0 text-start border-none">
                    <h4 className="color-info">¡Bienvenido de vuelta!</h4>
                  </div>
                </div>
              </div>

              <div className="card-body  mt-0">
                <Alert_MUI
                  color={"error"}
                  title={"Error"}
                  msg={error}
                  open={isError}
                  onClose={() => {
                    setIsError(false);
                  }}
                />
                <div className="row m-2  mt-5 col-lg-12 col-sm-12 col-12" id="">
                  <CssTextField
                    required
                    value={username}
                    className=""
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    onChange={(ev) => {
                      setUsername(ev.target.value);
                    }}
                    label="Nombre de usuario"
                  />
                </div>
                <div className="row m-2 mt-5  col-lg-12 col-sm-12 col-12" id="">
                  <CssTextField
                    required
                    className=""
                    type={"password"}
                    value={password}
                    InputLabelProps={{ shrink: true }}
                    onChange={(ev) => setPassword(ev.target.value)}
                    label="Contraseña"
                  />
                </div>
                <div
                  className="row mt-1 ms-auto me-auto col-lg-12 col-sm-12 col-12"
                  id="rowLoginInputAndLabel"
                >
                  <div className="col-11 col-lg-12 col-sm-11  text-end">
                    <a className="text-secondary" href={"/auth/reset/pass"}>
                      Olvidé contraseña
                    </a>
                  </div>
                </div>
                <div
                  hidden={no_need2fa}
                  className="row m-2  mt-5  ms-auto me-auto col-lg-12 col-sm-12 col-12"
                  id="rowLoginInputAndLabel"
                >
                  <CssTextField
                    required
                    className=""
                    type={"text"}
                    value={twoFactor}
                    onChange={(ev) => {
                      setTwoFactor(ev.target.value);
                    }}
                    label="Código 2FA"
                  />
                </div>
              </div>
              <div className="card-footer border-none bg-transparent">
                <div className="row w-100  justify-content-center align-content-center d-flex mt-4">
                  <div className="col-10 col-sm-10 col-lg-10 justify-content-center align-content-center d-flex">
                    <input
                      onClick={onLogin}
                      type="submit"
                      disabled={
                        username === "" ||
                        username === null ||
                        password === null ||
                        password === ""
                      }
                      id="loginsub"
                      value={textButton}
                      className="button-Primary w-75 "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
