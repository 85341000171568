import {Navigate} from "react-router-dom";
class Validaciones {

    static deshabilitarBotones(nodeFather) {
        var nodesToDisab = document.getElementsByClassName(nodeFather);

        for (var i = 0; i < nodesToDisab.length; i++) {
            nodesToDisab[i].disabled = true;
        }
    }

    static habilitarBotones(nodeFather) {
        var nodesToDisab = document.getElementsByClassName(nodeFather);

        for (var i = 0; i < nodesToDisab.length; i++) {
            nodesToDisab[i].disabled = false;
        }
    }

    static convertDateToSpanishDate (date) {
        const dateIngles = date.substring(0,10);
        return dateIngles.substring(8,10) + '/' + dateIngles.substring(5,7) +  '/' + dateIngles.substring(0,4)
    }

    
}
export default Validaciones;


export const RedirectAs404 = ({ location }) => {
  
    return (    
    <Navigate to={Object.assign({}, location, { state: { is404: true } })} />
  )};

