import React from "react";
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorImage from "../../Assets/images/jsTree/error-404.svg";

const Error404Modern = () => {
  return (
    
      <Box className="nk-block-middle wide-md mx-auto">
        <Box className="nk-error-ld text-center">
          <img className="nk-error-gfx" src={ErrorImage} alt="error" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">Opps, esta página no existe</h3>
            <p className="nk-error-text">
              Sentimos el inconveniente. Parece que intentaste acceder a una página que fue borrada o que nunca existió.
            </p>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button  size="lg" className="mt-2 button-Primary">
                Regresar al inicio
              </Button>
            </Link>
          </div>
        </Box>
      </Box>
   
  );
};
export default Error404Modern;
