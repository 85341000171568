import { useReducer } from "react";
import AuthReducer from "./AuthReducer";
import AuthContext from "./AuthContext";
import { LOGIN, FORGOT, REGISTER, USER } from "../types";
import { BASE_URL } from "../../Services/Utils/baseUrl";
import axios from "axios";

const AuthState = ({ children }) => {
  const initialState = {
    accessToken: localStorage.getItem("accessToken") || null,
    userAuth: {}
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${BASE_URL}auth/login`, {
        username,
        password,
      });
      if (response.status === 200) {
        const { data } = response;
        dispatch({
          type: LOGIN,
          payload: data,
        });
        localStorage.setItem("accessToken", JSON.stringify(data.access_token));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      
      if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      else
      return {
        errorMsg: error.response.data.data.error_description,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const register_user = async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}auth/createUser`, data);
      if (response.status === 201) {
        const { data } = response;
        dispatch({
          type: REGISTER,
          payload: data,
        });

        return true;
      } else {
        return false;
      }
    } catch (error) {
      
    }
  };

  const forgot_password = async (email) => {
    try {
      const response = await axios.get(`${BASE_URL}auth/recovery-password?email=${email}&lifespan=300&redirect_uri=https://app.telerob.com.mx/`,);
      if (response.status === 200) {
        const { data } = response;
        dispatch({
          type: FORGOT,
          payload: data,
        });

        return true;
      } else {
        return false;
      }
    } catch (error) {
     
    }
  };

  const change_password = async (newpass) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(`${BASE_URL}auth/change-password`, newpass, config);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };


  const getUserAuth = async () => {
    const accessToken = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken").slice(1, -1) : "";

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const response = await axios.get(`${BASE_URL}auth/me`, config);
    
      if (response.status === 200) {
        dispatch({
          type: USER,
          payload: response.data,
        });
        return response.data
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const cleanUserAuth = async() => {
    localStorage.setItem("accessToken", "");

    dispatch({
      type: USER,
      payload: {},
    });
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken: state.accessToken,
        login,
        register_user,
        forgot_password,
        change_password,
        userAuth: state.userAuth,
        getUserAuth,
        cleanUserAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
