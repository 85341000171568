import React, { useState } from "react";
import axios from "axios";
import {
  Route,
  BrowserRouter as Router,
  Link,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Switch from "@mui/material/Switch";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AlertDialog from "../../Components/Components/AlertDialog";
import { NumberFormatBase } from "react-number-format";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import { FormControlLabel } from "@mui/material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3c78bc",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3c78bc",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3c78bc",
    },
    "&:hover fieldset": {
      borderColor: "#3c78bc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3c78bc",
    },
  },
});

const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#3c78bc",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3c78bc",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3c78bc",
    },
    "&:hover fieldset": {
      borderColor: "#3c78bc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3c78bc",
    },
  },
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
});

export default function ContactChange_Card(props) {
  const {
    getAllSpecialist,
    specialists,
    editUsers,
    editSpecialist,
    getAllSpecialisties,
    specialities,
  } = React.useContext(GeneralContext);
  const [alertInfo, setAlertInfo] = React.useState({});
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [medicalSpec, setMedSpec] = React.useState([]);
  const [stateS, setState] = React.useState(specialists[0]?.state);
  const [alertColor, setAlertColor] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const onSign = async (ev) => {
    ev.preventDefault();
    if (props.isSpecil) {
      const resp = await editSpecialist(
        {
          firstName,
          lastName,
          email,
          phone_number:phone,
          state: stateS,
          
        },
        specialists[0].id
      ).then((next)=>{
        setAlertColor("success");
        setAlertMsg("Información editada exitosamente");
        setAlertTitle("Informacion");
        setAlertOpen(true);
        props.onSaveSpecialist();
      });
      if (resp?.errorStatus === undefined) {
      
      } else {
        if (resp?.errorStatus === 409) {
          setAlertColor("error");
          setAlertMsg(
            "Ha ocurrido un error de conflicto, revise el correo y teléfono"
          );
          setAlertTitle("Error");
          setAlertOpen(true);
        } else {
          setAlertColor("error");
          setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
        }
      }
    } else {
      const resp = editUsers(
        {
          firstName,
          lastName,
          email,
        },
        props.id
      );

      if (resp?.errorStatus === undefined) {
        setAlertColor("success");
        setAlertMsg("Información editada exitosamente");
        setAlertTitle("Información");
        setAlertOpen(true);
      } else {
        if (resp?.errorStatus === 409) {
          setAlertColor("error");
          setAlertMsg(
            "Ha ocurrido un error de conflicto, revise el correo y teléfono"
          );
          setAlertTitle("Error");
          setAlertOpen(true);
        } else {
          setAlertColor("error");
          setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
        }
      }
    }

    props.onChange({
      firstName,
      lastName,
      email,
    });
  };

  const changeEnabled = () => {
    setState(stateS === 0 ? 1 : 0);
  };

  const onChangeSpeciality = (ev) => {
    setMedSpec(ev.target.value);
  };

  const chargeInfo = async() => {
    await getAllSpecialisties();

    //await getAllSpecialist(undefined,props.id);
  }

  React.useEffect(() => {
    chargeInfo();
  }, []);

  const prepareSpecialities = (arraySpec) => {   
    const valueRet =  arraySpec?.map((item)=>{
      return item?.medical_speciality.id
    });

    return valueRet;
  }

  React.useEffect(() => {    
    setFirstName(props.firstName);
    setLastName(props.lastName);
    setEmail(props.email);
    setPhone(props.phone);
    setState(specialists[0]?.state);
    
  }, [props.firstName, props.lastName, props.email, props.phone]);

  return (
    <div className="card mt-4 p-1 shadow h-100 rounded-15">
      <Alert_MUI
        color={alertColor}
        title={alertTitle}
        msg={alertMsg}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <div className="card-title m-2">
        <h5>Información personal</h5>
      </div>
      <div className="card-header p-1">
        <div
          className="row m-1 bg-transparent d-flex
           align-content-around "
        >
          <div className="col-lg-4 col-sm-12 col-12 p-1 mt-2">
            <CssTextField
              value={firstName}
              label="Nombre"
              onChange={(ev) => setFirstName(ev.target.value)}
              fullWidth
              name="name"
              id="user_gainInput"
            />
          </div>
          <div className="col-lg-4 col-sm-12 col-12 p-1  mt-2">
            <CssTextField
              fullWidth
              value={lastName}
              label="Apellidos"
              onChange={(ev) => setLastName(ev.target.value)}
              name="lastname"
              id="lastname"
            />
          </div>
          <div className="col-lg-4 col-sm-12 col-12 p-1  mt-2">
            <CssTextField
              fullWidth
              value={email}
              label="Correo"
              onChange={(ev) => setEmail(ev.target.value)}
              name="email"
              id="email"
            />
          </div>
          {props.isSpecil ? (
            <div className="col-lg-4 col-sm-12 col-12 p-1  mt-2">
              <CssTextField
                fullWidth
                value={phone}
                label="Telefono"
                onChange={(ev) => setPhone(ev.target.value)}
                name="phone"
                id="phone"
              />
            </div>
          ) : null}
          {props.isSpecil && prepareSpecialities(specialists[0]?.medical_specialities)?.length > 0 ? (
            <div  className="col-lg-4 col-sm-12 col-12 p-1  mt-2">
   <FormControl fullWidth disabled>
              <InputLabel id="demo-multiple-name-label">
                Especialidad
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={
                  medicalSpec.length === 0
                    ? prepareSpecialities(specialists[0]?.medical_specialities) 
                    : medicalSpec
                }
                onChange={(ev) => onChangeSpeciality(ev)}
                input={<OutlinedInput label="Especialidad" />}
              >
                {specialities?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
       
            </div>
            ) : null}
         
        </div>
      </div>

      <div className="card-footer row justify-content-end d-flex align-content-end mt-auto">
        <div className="col-lg-5 col-sm-12 col-12 p-1">
          <div className="row w-100 justify-content-between d-flex align-content-between">
            <div className="col-5 "></div>
            <div className="col-5 ">
              <input
                id="passChange_Btn"
                type="submit"
                onClick={onSign}
                value="Guardar"
                className="w-100 button-Primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


/*
 {props.isSpecil ? (
            <div className="col-lg-4 col-sm-12 col-12 p-1  mt-2">
              <FormControlLabel
                sx={{
                  display: "block",
                }}
                key={specialists[0]?.state}
                control={
                  <Switch
                    checked={stateS === 0 ? true : false}
                    onChange={changeEnabled}
                    color="primary"
                  />
                }
                label={stateS === 0 ? "Online" : "Offline"}
              />
            </div>
          ) : null}
*/