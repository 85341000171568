import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faChartLine } from "@fortawesome/free-solid-svg-icons";

import IconButton from "@mui/material/IconButton";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./style.css";

import logo from "../../../Assets/Logotipo Telerob (2)_Page_01.png";
import React from "react";
import Validaciones from "../../../Services/Utils/Validaciones";
import GeneralContext from "../../../Contexts/general-context/GeneralContext";

export default function NavBar_Bootstrap(props) {
  const history = useNavigate();
  const { getMenuBar, menu } = React.useContext(GeneralContext);

  const { itemActive, title, totalNotif, ...other } = props;

  React.useEffect(() => onLoadActive(), []);

  const onLoadActive = () => {
    getMenuBar();
    const linkColor = document.querySelectorAll(".nav_link");
    linkColor.forEach((l, index) => {
      if (index !== itemActive) l.classList.remove("active");
      else {
        l.classList.add("active");
      }
    });
  };

  const logout = () => {
    history("/auth-login");
  };

  const onHandleClick = (ev) => {
    const linkColor = document.querySelectorAll(".nav_link");
    linkColor.forEach((l) => {
      if (l !== ev.target && l !== ev.target.parentElement)
        l.classList.remove("active");
      else {
        l.classList.add("active");
      }
    });
  };
  /*
  document.addEventListener("DOMContentLoaded", function (event) {
    //showNavbar("header-toggle", "nav-bar", "body-pd", "header");

    /*===== LINK ACTIVE =====*/
  /* const linkColor = document.querySelectorAll(".nav_link");

    function colorLink() {
      if (linkColor) {
        linkColor.forEach((l) => l.classList.remove("active"));
        this.classList.add("active");
      }
    }
    linkColor.forEach((l) => l.addEventListener("click", colorLink));

    // Your code to run since DOM is loaded and ready
  });
*/
  return (
    <div>
      <div class="header shadow " id="header">
        <div className="d-flex align-items-center">
          <div class="header_toggle h-100 d-flex align-items-center">
            <i
              class="bx bx-menu"
              id="header-toggle"
              onClick={(ev) => {
                const toggle = ev.target,
                  nav = document.getElementById("nav-bar"),
                  bodypd = document.getElementById("body-pd"),
                  headerpd = document.getElementById("header-toggle");

                nav.classList.toggle("show");
                // change icon
                toggle.classList.toggle("bx-x");
                // add padding to body
                bodypd.classList.toggle("body-pd");
                // add padding to header
                headerpd.classList.toggle("header-pd");
              }}
            ></i>
          </div>

          <div className="ms-1  h-100">
            <h4 className="text-dark mt-lg-0 mt-auto mb-auto mt-sm-2 ">
              {title}
            </h4>
          </div>
        </div>
        <div class="header_img">
          <IconButton
            className="color-info"
            onClick={() => history("/notificaciones")}
            size="large"
            aria-label="show 17 new notifications"
            color={"primary"}
          >
            <Badge badgeContent={totalNotif} color="error">
              <NotificationsIcon color={"primary"} />
            </Badge>
          </IconButton>
          <IconButton
            onClick={logout}
            size="large"
            aria-label="show 17 new notifications"
            className="color-info"
          >
            <ExitToAppIcon />
          </IconButton>{" "}
          <img src="https://i.imgur.com/hczKIze.jpg" alt="" />{" "}
        </div>
      </div>

      <div
        class="l-navbar shadow"
        onBlur={(ev) => {
          const toggle = document.getElementById("header-toggle"),
            nav = document.getElementById("nav-bar"),
            bodypd = document.getElementById("body-pd"),
            headerpd = document.getElementById("header-toggle");

          nav.classList.toggle("show");
          // change icon
          toggle.classList.toggle("bx-x");
          // add padding to body
          bodypd.classList.toggle("body-pd");
          // add padding to header
          headerpd.classList.toggle("header-pd");
        }}
        id="nav-bar"
      >
        <nav class="nav">
          <div>
            {" "}
           
            <div class="nav_list">
              {menu.map((item) => (
                <a
                  key={item.id}
                  class="nav_link cursor-pointer"
                  onClick={(ev) => {
                    onHandleClick(ev);
                    history(item.value);
                  }}
                >
                  {" "}
                  <i class={"bx nav_icon " + item.icon}></i>{" "}
                  <span class="nav_name">{item.label}</span>{" "}
                </a>
              ))}
             
           
            </div>
          </div>{" "}
        </nav>
      </div>
    </div>
  );
}

NavBar_Bootstrap.propTypes = {
  itemActive: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalNotif: PropTypes.number.isRequired,
};

/*
 <a
                class='nav_link cursor-pointer'
                onClick={(ev) => {
                  onHandleClick(ev);
                  history("/assign");
                }}>
                {" "}
                <i class='bx bx-map-pin nav_icon'></i>{" "}
                <span class='nav_name'>Asignación de Casos</span>{" "}
              </a>{" "}
              
                <a
                class='nav_link cursor-pointer'
                onClick={(ev) => {
                  onHandleClick(ev);
                  history("/users");
                }}>
                {" "}
                <i class='bx bx-group nav_icon'></i>{" "}
                <span class='nav_name'>Usuarios</span>{" "}
              </a>
               
              <a
                class='nav_link cursor-pointer'
                onClick={(ev) => {
                  onHandleClick(ev);
                  history("/profile");
                }}>
                {" "}
                <i class='bx bx-user nav_icon'></i>{" "}
                <span class='nav_name'>Perfil</span>{" "}
              </a>
              
<a
                class='nav_link cursor-pointer'
                onClick={(ev) => {
                  onHandleClick(ev);
                  history("/specialists");
                }}>
                {" "}
                <i class='bx bx-user nav_icon'></i>{" "}
                <span class='nav_name'>Especialistas</span>{" "}
              </a>
 <a
                id='notificaciones'
                class='nav_link active cursor-pointer'
                onClick={(ev) => {
                  onHandleClick(ev);
                  history("/");
                }}>
                {" "}
                <i class='bx bx-home-alt-2 nav_icon'></i>{" "}
                <span class='nav_name'>Inicio</span>{" "}
              </a>{" "}
              <a
                class='nav_link cursor-pointer'
                onClick={(ev) => {
                  onHandleClick(ev);
                  history("/users");
                }}>
                {" "}
                <i class='bx bx-user nav_icon'></i>{" "}
                <span class='nav_name'>Usuarios</span>{" "}
              </a>{" "}
          
*/

/*
<NavBar_Bootstrap />;

<nav class='navbar p-0 fixed-top d-flex flex-row'>
        <div class='navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center'>
          <a class='navbar-brand brand-logo-mini ' href='/'>
            <img src={logo} alt='logo' /> Stamina Investment
          </a>
        </div>
        <div class='navbar-menu-wrapper flex-grow d-flex align-items-stretch'>
          <button
            class='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#sidebar'
            aria-controls='sidebar'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span class='navbar-toggler-icon'></span>
          </button>
          <ul class='navbar-nav w-100'>
            <li class='nav-item w-100'></li>
          </ul>
          <ul class='navbar-nav navbar-nav-right'>
            <li class='nav-item  border-left'>
              <a
                class=' count-indicator cursor-pointer'
                id='messageDropdown'
                onClick={(ev) => {
                  ev.preventDefault();
                  history("/notificaciones");
                }}>
                <FontAwesomeIcon
                  icon={faBell}
                  size='1x'
                  color='rgba(171, 187, 184, 0.96)'
                />
              </a>
            </li>
            <li class='nav-item dropdown'>
              <a
                class='nav-link'
                id='profileDropdown'
                href='#'
                data-toggle='dropdown'>
                <div class='navbar-profile'>
                  <FontAwesomeIcon
                    icon={faUser}
                    size='1x'
                    color='rgba(171, 187, 184, 0.96)'
                  />
                  <p class='mb-0 d-none d-sm-block navbar-profile-name'>
                    Henry Klein
                  </p>
                  <i class='mdi mdi-menu-down d-none d-sm-block'></i>
                </div>
              </a>
              <div
                class='dropdown-menu dropdown-menu-right navbar-dropdown preview-list'
                aria-labelledby='profileDropdown'>
                <h6 class='p-3 mb-0'>Profile</h6>
                <div class='dropdown-divider'></div>
                <a class='dropdown-item preview-item'>
                  <div class='preview-thumbnail'>
                    <div class='preview-icon bg-dark rounded-circle'>
                      <i class='mdi mdi-settings text-success'></i>
                    </div>
                  </div>
                  <div class='preview-item-content'>
                    <p class='preview-subject mb-1'>Settings</p>
                  </div>
                </a>
                <div class='dropdown-divider'></div>
                <a class='dropdown-item preview-item'>
                  <div class='preview-thumbnail'>
                    <div class='preview-icon bg-dark rounded-circle'>
                      <i class='mdi mdi-logout text-danger'></i>
                    </div>
                  </div>
                  <div class='preview-item-content'>
                    <p class='preview-subject mb-1'>Log out</p>
                  </div>
                </a>
                <div class='dropdown-divider'></div>
                <p class='p-3 mb-0 text-center'>Advanced settings</p>
              </div>
            </li>
          </ul>
          <button
            class='navbar-toggler navbar-toggler-right d-lg-none align-self-center'
            type='button'
            data-toggle='offcanvas'>
            <span class='mdi mdi-format-line-spacing'></span>
          </button>
        </div>
      </nav>
   
*/
