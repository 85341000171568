import React from "react";
import axios from "axios";
import {
  Route,
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  withRouter,
  useNavigate,
} from "react-router-dom";

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import Alert_MUI from "../../Components/Components/Alert_MUI";
import { styled } from "@mui/material/styles";
import AuthContext from "../../Contexts/auth-context/AuthContext";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3c78bc",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3c78bc",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3c78bc",
    },
    "&:hover fieldset": {
      borderColor: "#3c78bc",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3c78bc",
    },
  },
});



export default function PasswordChange_Card(props) {

  const history = useNavigate();

  const { change_password } = React.useContext(AuthContext);
  const [passActual, setPassActual] = React.useState("");
  const [passNew, setPassNew] = React.useState("");
  const [passNewConfirm, setPassNewConfirm] = React.useState("");
  const [alertInfo, setAlertInfo] = React.useState({});

  //Errores

  const [actualPass_Error_Text, setActualPass_Error_Text] = React.useState("");
  const [actualPass_Error_Visib, setActualPass_Error_Visib] =
    React.useState(false);

  const [newPass_Error_Text, setNewPass_Error_Text] = React.useState("");
  const [newPass_Error_Visib, setNewPass_Error_Visib] = React.useState(false);

  const [newConfPass_Error_Text, setNewConfPass_Error_Text] =
    React.useState("");
  const [newConfPass_Error_Visib, setNewConfPass_Error_Visib] =
    React.useState(false);

  const onCancel = () => {
    setPassActual("");
    setPassNew("");
    setPassNewConfirm("");
  };

  const onSave = async() => {
    setNewPass_Error_Visib(false);
    setNewConfPass_Error_Visib(false);
    if (passNewConfirm !== passNew && passNew !== "" && passNewConfirm !== "") {
      onCancel();
      //Las contraseñas deben coincidir
      setNewPass_Error_Text("Las contraseñas deben coincidir");
      setNewPass_Error_Visib(true);
      setNewConfPass_Error_Text("Las contraseñas deben coincidir");
      setNewConfPass_Error_Visib(true);
    } else if (passActual === passNewConfirm) {
      onCancel();
      //La nueva contra debe ser distinta de la vieja
      setNewPass_Error_Text("La nueva contraseña debe ser distinta de la anterior");
      setNewPass_Error_Visib(true);
    } else {
      //Change Password
      const resp = await change_password({ new_password:passNew});
      if(resp?.errorStatus)
      setAlertInfo({
    visibility:true,
    type: "error",
    title:"Error",
    msg: "No se pudo cambiar la contraseña"
    });
    else
    setAlertInfo({
      visibility:true,
      type: "success",
      title:"Operación exitosa",
      msg: "Contraseña cambiada"
      });
    }
  };

  return (
    <div className='card p-1 h-100 shadow rounded-15'>
      <Alert_MUI
        color={alertInfo.type}
        title={alertInfo.title}
        msg={alertInfo.msg}
        open={alertInfo.visibility}
        onClose={() => {
          setAlertInfo({
            visibility: false,
          });
        }}
      />
      <div className='card-title m-2 ms-0 '>
       
        <h5 className="ms-2 mt-auto mb-auto">Seguridad</h5>
      </div>
      <div className='card-header'>
        <div
          className='row  bg-transparent justify-content-start d-flex
           align-content-start '>
        
          <div className='col-lg-4 col-sm-12 col-12 p-1  mt-2'>
            <CssTextField
              className='w-90'
              error={newPass_Error_Visib}
              label='Nueva contraseña'
              value={passNew}
              helperText={newPass_Error_Text}              
              type={"password"}
              onChange={(ev) => {
                setPassNew(ev.target.value);
              }}
            />
          </div>
          <div className='col-lg-4 col-sm-12 col-12 p-1  mt-2'>
            <CssTextField
              
              className='w-90'
              error={newConfPass_Error_Visib}
              label='Confirmar nueva contraseña'
              value={passNewConfirm}
              helperText={newConfPass_Error_Text}
             
              type={"password"}
              onChange={(ev) => {
                setPassNewConfirm(ev.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='card-footer row justify-content-end d-flex align-content-end'>
        <div className='col-lg-5 col-sm-12 col-12 p-1'>
          <div className='row w-100 justify-content-between d-flex align-content-between'>
            <div className='col-5 '>
              
            </div>
            <div className='col-5 '>
              <input
                id='passChange_Btn'
                type='submit'
                disabled={
                  passActual === "" || passNewConfirm === "" || passNew === ""
                }
                onClick={onSave}
                value='Guardar'
                className='w-100 button-Primary'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
