import React, { useState } from "react";
import TempleteReportes from "../../Components/Containers/TempleteReportes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import { Delete, Edit } from "@mui/icons-material";
import CustomDialog from "../../Components/Components/CustomDialog";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import NavBar_Bootstrap from "../../Components/Components/NavBars/NavBar_Bootstrap";
import AlertDialog from "../../Components/Components/AlertDialog";
import exportFromJSON from "export-from-json";
import {useNavigate} from "react-router-dom";

export default function UserList(props) {
  const {
    users,
    addUsers,
    getAllUsers,
    editUsers,
    removeUser,
    getIdUser,
    changeRoleToUser,
    user,
  } = React.useContext(GeneralContext);
  const [filtrerBy, setfiltrerBy] = useState("");
  const [dataForLoad, setdataForLoad] = useState([]);
  const [view, setview] = useState("Todas");
  const [loading, setloading] = useState(true);
  const [justloading, setjustloading] = useState("show");
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setpage] = useState(0);
  const [search, setsearch] = useState("");
  const [totalItems, settotalItems] = useState(0);
  const [orderSelected, setorderSelected] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [itemSelectedToDialog, setItemSlected] = useState({});
  const [operation, setOperation] = useState("New");
  const [alertColor, setAlertColor] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [visibilityAlertConfirm, setvisibilityAlertConfirm] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const history = useNavigate();

  const onOrderSelect = (id) => {
    
    setorderSelected(id);
    setItemSlected({
      id: id.id,
      nombre_usuario: id.username,
      nombre: id.firstName,
      apellidos: id.lastName,
      correo: id.email,
      roles: [
        {
          label: "Operador",
          value: "operator",
        },
        {
          label: "Administrador",
          value: "administrator",
        },
      ],
    });
  };

  const onButtonEdit = async (item) => {
    let roles = [
      {
        label: "Operador",
        value: "operator",
      },
      {
        label: "Administrador",
        value: "administrator",
      },
    ];

    getIdUser(item.id).then((resp) => {
      const newRoles = roles.sort(function (a, b) {
        if (a.value === resp?.data?.realmRoles[0]) {
          return -1;
        }
        if (a.value !== resp?.data?.realmRoles[0]) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

     

      setItemSlected({
        id: item.id,
        nombre_usuario: item.username ? item.username : "",
        nombre: item.firstName ? item.firstName : "",
        apellidos: item.lastName ? item.lastName : "",
        correo: item.email ? item.email : "",
        roles: newRoles,
      });
     
      setOperation("Edit");
      //this.props.history.push("/orden/" + orderSelected.id);
      setOpenDialog(true);
    }).catch((error) => {
      if (error?.response?.data?.statusCode === 401)
      history("/auth-login");
    });
  };

  const onButtonDelete = async (item) => {

    await removeUser(orderSelected.id);
    setvisibilityAlertConfirm(false);
    setAlertColor("success");
    setAlertMsg("Usuario eliminado con éxito");
    setAlertTitle("Operación exitosa");
    setAlertOpen(true);
    await getAllUsers(rowsPerPage, 0, "", search);
  };

  const onHandleSelectionViewOrders = (select) => {
    setview(select);
    setloading(true);

    if (select === null || select === undefined) {
      select = "Todas";
    }

    this.loadInfoReporte(select, rowsPerPage, page, search);
  };

  React.useEffect(() => {
    getAllUsers(rowsPerPage, 0, "", "").then((next)=>
    setloading(false)
    );
   
  }, []);

  const onChangeRowPerPage = (rowsCount) => {
    setrowsPerPage(rowsCount);
    loadInfoReporte(view, rowsCount, page, search);
  };

  const onChangePage = (pageActually) => {
    setpage(pageActually);
   
    loadInfoReporte(view, rowsPerPage, pageActually, search);
  };

  const loadInfoReporte = async (selection, rowsPerPage, pageNew, search) => {
    setloading(false);
  
    await getAllUsers(rowsPerPage, pageNew, selection, search);
   
  };

  const onChangeFiltrered = (ev) => {
    setfiltrerBy(ev.target.value);
    setloading(true);
  };

  const isDisabled = (row) => {
    if (row.state === "Terminado") return true;
    else return false;
  };

  const onChangeSearch = async (filter, value) => {
   
    setsearch(value);
    setfiltrerBy(filter);
    setloading(true);
    await getAllUsers(rowsPerPage, 0, filter, value);
    setloading(false);
  };

  const onSaveData = async (data) => {
    
    setLoadingDialog(true);
    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (data.correo && data.correo !== "" && !validEmail.test(data.correo)) {
      setAlertColor("error");
      setAlertMsg("Introduzca un correo válido");
      setAlertTitle("Error");
      setAlertOpen(true);
      setLoadingDialog(false);
    } else {
      if (operation === "New") {
        data.enabled = true;
        if (data.roles.length > 1) data.roles = ["operator"];
        const dataToSave = {
          username: data.nombre_usuario,
          firstName: data.nombre,
          lastName: data.apellidos,
          email: data.correo,
          realmRoles: data.roles,
          enabled: true,
        };

        const resp = await addUsers(dataToSave);

        if (resp?.errorStatus === undefined) {
          setAlertColor("success");
          setAlertMsg("Usuario añadido con éxito");
          setAlertTitle("Operación exitosa");
          setAlertOpen(true);
          setOpenDialog(false);
          loadInfoReporte("Todas", 10, 0, "");
          setorderSelected({});
          setItemSlected({});
          setLoadingDialog(false);
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409)
            setAlertMsg(
              "Ha ocurrido un conflicto, revise su correo y nombre de usuario"
            );
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
          setLoadingDialog(false);
        }
      } else {
        if (data?.roles?.length > 1) data.roles = ["operator"];
        const resp = await editUsers(
          {
            username: data.nombre_usuario,
            firstName: data.nombre,
            lastName: data.apellidos,
            email: data.correo,
            enabled: data.enabled,
          },
          orderSelected.id
        );
        if(data.realmRoles && data.realmRoles.length > 0 ){
     
          if(data.realmRoles[0] !== itemSelectedToDialog.roles[0].value)
          changeRoleToUser(orderSelected.id, itemSelectedToDialog.roles[0].value,data.realmRoles[0] );
        }
       

        if (resp?.errorStatus === undefined) {
          setAlertColor("success");
          setAlertMsg("Usuario editado con éxito");
          setAlertTitle("Operación exitosa");
          setAlertOpen(true);
          setOpenDialog(false);
          loadInfoReporte("Todas", 10, 0, "");
          setorderSelected({});
          setItemSlected({});
          setLoadingDialog(false);
        } else {
          setAlertColor("error");
          if (resp?.errorStatus === 409)
            setAlertMsg(
              "Ha ocurrido un conflicto, revise su correo y nombre de usuario"
            );
          else setAlertMsg("Ha ocurrido un error");
          setAlertTitle("Error");
          setAlertOpen(true);
          setLoadingDialog(false);
        }
      }
    }
  };

  const inputData = {
    nombre_usuario: "",
    nombre: "",
    apellidos: "",
    correo: "",
    roles: [
      {
        label: "Operador",
        value: "operator",
      },
      {
        label: "Administrador",
        value: "administrator",
      },
    ],
  };

 

  const changeEnabled = (item) => {
    setOperation("Edit");
    onSaveData({ id: item.id, enabled: !item["enabled"] });
  };

  const onClickButtonDelete = (item) => {
    setItemSlected({
      id: item.id,
      username: item.nombre_usuario,
      firstName: item.nombre,
      lastName: item.apellidos,
      email: item.correo,
    });
    setvisibilityAlertConfirm(true);
  };

  const exportData = () => {
    const usersToExport = users.map((item) => {
      return {
        Nombre: item.firstName,
        Apellidos: item.lastName,
        Correo: item.email,
        Habilitado: item.enabled ? "Si" : "No",
      };
    });
    const fileName = "Usuarios";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: usersToExport, fileName, exportType });
  };

  return (
    <div className="container-fluid bg-lightBlue  h-100 w-100 p-0" id="body-pd">
    
      <NavBar_Bootstrap
        userAuth={props.user}
        itemActive={4}
        title={"Listado de usuarios"}
        onLogout={props.onLogout}
      />
      <AlertDialog
        color={"info"}
        title={"Confirmación"}
        msg={"Esta seguro de eliminar a " + itemSelectedToDialog.nombre+"?"}
        open={visibilityAlertConfirm}
        onSave={onButtonDelete}
        onClose={() => setvisibilityAlertConfirm(false)}
      />
      <Alert_MUI
        color={alertColor}
        title={alertTitle}
        msg={alertMsg}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <TempleteReportes
        changeEnabled={changeEnabled}
        page={page}
        export={exportData}
        rowsPerPage={rowsPerPage}
        onChangeRowPerPage={onChangeRowPerPage}
        onChangePage={onChangePage}
        user={""}
        loading={loading}
        totalItems={users.length}
        onOrderSelect={onOrderSelect}
        onHandleSelectionViewOrders={onHandleSelectionViewOrders}
        columnasName={namesColumn}
        columnsValue={columnsToFill}
        nameReporte="Listado de usuarios"
        urlToRedirect="/orden/"
        optionsHowToFiltered={namesColumn}
        dataLoad={users}
        optionsHowToSee={[
          {
            value: "email",
            label: "Correo",
          },
          {
            value: "firstName",
            label: "Nombre",
          },
          {
            value: "lastName",
            label: "Apellido",
          },
        ]}
        valuesForColumnColumn={valuesForColumnColumn}
        valueForButton={"Ver "}
        onSearch={onChangeSearch}
        isButtonDisable={true}
        buttonsArray={[
          {
            id: "resp-button",
            label: "Editar",
            functionOnClick: onButtonEdit,
            className: "button-Secondary",
            icon: <Edit />,
            disabled: () => {
              return false;
            },
          },
          {
            id: "resp-button",
            label: "Eliminar",
            functionOnClick: onClickButtonDelete,
            className: "button-Error-Outlined",
            icon: <Delete />,
            disabled: () => {
              return false;
            },
          },
        ]}
        buttonGeneral={{
          value: "Nuevo",
          className: "button-Primary .w-250px",
          onClick: (ev) => {
            ev.preventDefault();
            setorderSelected({});
            setItemSlected({ ...inputData });
            setOperation("New");
            setOpenDialog(true);
          },
        }}
      />
      <CustomDialog
        open={openDialog}
        title="Datos del usuario"
        onCancel={() => {
          setOpenDialog(false);
          setItemSlected({});
        }}
        loading={loadingDialog}
        onSave={onSaveData}
        data={itemSelectedToDialog}
        requiredCampos={["nombre_usuario", "nombre", "apellidos", "correo"]}
        disabledCampos={["nombre_usuario"]}
        operation={operation}
      />
    </div>
  );
}

let namesColumn = [
  "Historia Clínica",
  "Nombre del Paciente",
  "Especialidad Médica",
  "Nombre del Especialista",
  "Asignado Por",
  "Fecha",
  "Estado",
];

let valuesForColumnColumn = [];

let columnsToFill = [
  {
    id: "username",
    label: "Usuario",
    minWidth: 120,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "firstName",
    label: "Nombre",
    minWidth: 120,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "lastName",
    label: "Apellidos",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Correo Elect.",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "emailVerified",
    label: "Email Verificado",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "enabled",
    label: "Habilitado",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
];
