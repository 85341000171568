
import React from "react";

import Alert_MUI from "../../Components/Components/Alert_MUI";
import ImageForgot from "../../Assets/undraw_forgot_password_re_hxwm.svg";
import AuthContext from "../../Contexts/auth-context/AuthContext"
import {useNavigate} from "react-router-dom";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#03b4cf",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#03b4cf",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#03b4cf",
    },
    "&:hover fieldset": {
      borderColor: "#03b4cf",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#03b4cf",
    },
  },
});

export default function Forgot_Password(props) {
  const { hash, ...other } = props;
  const { forgot_password } = React.useContext(AuthContext);

  const [email, setEmail] = React.useState("");

  const [isError, setIsError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [errorTitle, setErrorTitle] = React.useState("");
  const [colorAlert, setColorAlert] = React.useState("info");
  const [showEmail, setShowEmail] = React.useState(true);
  const [hashState, setHashState] = React.useState(hash);
  const [password, setPassword] = React.useState("");
  const [passCofirm, setPassCofirm] = React.useState("");

  const history = useNavigate();


  const onSubmitt = async(ev) => {
    ev.preventDefault();

    const res = await forgot_password(email);

    if (res) {
      setIsError(true);
      setErrorTitle("Success");
      setErrorMsg("Correo enviado exitosamente");
      setColorAlert('success');
     //history('/auth-login');

    } else {
      setErrorTitle("Error");
      setIsError(true);
      setErrorMsg("Correo incorrecto");
      setColorAlert('error');
      //history('/auth-login')
     
    }
  };

  return (
    <div className="container-fluid w-100 h-100 ">
      <Alert_MUI
        color={colorAlert}
        title={errorTitle}
        msg={errorMsg}
        open={isError}
        onClose={() => {
          setIsError(false);
        }}
      />

<div className="col-lg-9 col-sm-12 col-12 m-auto justify-content-center align-content-center d-flex mt-4 h-100 align-items-center">
        <div className="row glass-panel w-100 shadow rounded-15 m-auto justify-content-center  align-content-center d-flex">
          <div className="col-lg-6 col-sm-9 col-9  justify-content-center align-content-center d-flex text-center">
            <div className="card border-none text-center bg-transparent ms-auto me-auto">
            <img src={ImageForgot} height="80%" width="80%" />
            </div>
          </div>
          <div className="col-lg-5 col-sm-12 col-12 m-2 mb-1 mt-0">
            <div className="card bg-transparent border-none text-end">
              <div className="card-header bg-transparent p-0 border-none ms-auto me-auto justify-content-center d-flex align-content-center">
                <div className="card m-auto text-center bg-transparent border-none">
                  <div className="card-header m-0 text-center bg-transparent border-none"></div>
                  <div className="card-body p-0 m-0 text-start border-none">
                    <h4 className="color-info">Recuperar contraseña</h4>
                  </div>
                </div>
              </div>

              <div className="card-body  mt-0">
                
                <div className="row m-2  mt-3 col-lg-12 col-sm-12 col-12" id="">
                <CssTextField
                    required
                    fullWidth
                    className=""
                    type={"email"}
                    value={email}
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                    }}
                    label="Correo"
                  />
                </div>
               
               </div>
              <div className="card-footer border-none bg-transparent">
                   <div className="col-12 col-sm-12 col-lg-12 justify-content-around align-content-center d-flex ">
                  <input
              onClick={(ev) => {
                ev.preventDefault();
                history("/auth-login");
              }}
              id="backinput"
              type="submit"
              value="Regresar"
              className="button-Secondary w-50 "
            />
                    <input
                       onClick={onSubmitt}
                      type="submit"
                      disabled={
                        email === "" ||
                        email === null
                      }
                      id="loginsub"
                      value="Enviar"
                      className="button-Primary w-50 "
                    />
                  </div>
                 
               
              </div>
            </div>
          </div>
        </div>
      </div>








    </div>
  );
}
