import {
  USER,
  SETTINGS,
  PROVIDERS,
  GET_CONNECTION,
  MEDICALS_CENTER,
  ROLE,
  SPECIALIST,
  SPECIALITY,
  MEDICAL_CLASIFICATION,
  USERS,
  SPECIALISTS,
  MENU,
  REPORT_CASE,
  ASSIGN,
} from "../types";

const GeneralReducer = (prevState, { type, payload }) => {
  switch (type) {
    case USERS:
      return {
        ...prevState,
        users: payload,
      };
    case USER:
      return {
        ...prevState,
        user: payload,
      };
    case ROLE:
      return {
        ...prevState,
        roles: payload,
      };
    case SPECIALISTS:
      return {
        ...prevState,
        specialists: payload,
      };
    case SPECIALIST:
      return {
        ...prevState,
        specialist: payload,
      };
    case MEDICAL_CLASIFICATION:
      return {
        ...prevState,
        medicals_clasifications: payload,
      };
    case SPECIALITY:
      return {
        ...prevState,
        specialities: payload,
      };
    case MENU:
      return {
        ...prevState,
        menu: payload,
      };
    case REPORT_CASE:
      return {
        ...prevState,
        report_cases: payload,
      };
    case ASSIGN:
      return {
        ...prevState,
        assignments: payload,
      };
    case MEDICALS_CENTER:
      return { ...prevState, medicals_centers: payload };
    
    default:
      return prevState;
  }
};

export default GeneralReducer;
