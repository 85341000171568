import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


export default function AlertDialog(props) {
  const { children, onClose, open, onSave, title, msg, color,className, ...other } =
    props;

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave();
  };

  return (
    
      <Dialog id={'confirmDialog'} className={open ? " opacity-100" : " opacity-0"}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
       >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' className="button-Secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
          className="button-Primary"
            variant='contained'
            onClick={handleSave}
            >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    
  );
}

