import React from "react";
import Select from "react-select";

const customStyles = {
  
  control: (defaultStyles) => ({
    ...defaultStyles,
    border:"#3c78bc solid 1px !important",
    height:"45px",
    borderRadius: "10px",
  }),
  
};

const RSelect = ({ ...props }) => {
  return (
    <div className=" ms-2  ">
      <Select
        className={`    ${props.className ? props.className : ""}`}
        styles={customStyles}
        {...props}
      />
    </div>
  );
};

export default RSelect;
