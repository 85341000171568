import { useReducer, useContext } from "react";
import GeneralReducer from "./GeneralReducer";
import GeneralContext from "./GeneralContext";
import {
  USER,
  SETTINGS,
  ROLE,
  SPECIALIST,
  SPECIALITY,
  MEDICAL_CLASIFICATION,
  USERS,
  SPECIALISTS,
  MENU,
  REPORT_CASE,
  ASSIGN,
  MEDICALS_CENTER,
} from "../types";
import { BASE_URL } from "../../Services/Utils/baseUrl";
import axios from "axios";
import AuthContext from "../auth-context/AuthContext";
import { useNavigate } from "react-router-dom";

const GeneralState = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  const initialState = {
    integration: {},
    users: [],
    user: {},
    settings: {},
    roles: [],
    specialists: [],
    specialist: {},
    specialities: [],
    medicals_clasifications: [],
    menu: [],
    report_cases: [],
    assignments: [],
    medicals_centers: [],
  };

  const [state, dispatch] = useReducer(GeneralReducer, initialState);

  const addUsers = async (data) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_URL}user`, data, config);
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const editUsers = async (data, id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.patch(`${BASE_URL}user/${id}`, data, config);
      if (res.status === 200 || res.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const getAllUsers = async (itemPerPage, page, searchBy, searchText) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (searchBy && searchText && searchBy !== "" && searchText !== "") {
        res = await axios.get(
          `${BASE_URL}user?${searchBy}=${searchText}`,
          config
        );
      } else {
        res = await axios.get(`${BASE_URL}user`, config);
      }
      if (res.status === 200) {
        dispatch({
          type: USERS,
          payload: res.data,
        });
        //getSettings();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const changeRoleToUser = async (idUser, role_to_delete, rol_new) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios
        .patch(
          `${BASE_URL}user/roles/${idUser}/remove`,
          {
            role_name: role_to_delete,
          },
          config
        )
        .then((next) => {
          const res = axios.patch(
            `${BASE_URL}user/roles/${idUser}/add`,
            {
              role_name: rol_new,
            },
            config
          );
          if (res.status === 200 || res.status === 204) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error?.response?.data?.statusCode === 401)
            window.location.replace("/auth-login");
          return {
            errorMsg: error.response.data.message,
            errorStatus: error.response.data.statusCode,
          };
        });
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const getIdUser = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let res = await axios.get(`${BASE_URL}user/${id}`, config);
    return res;
  };

  const removeUser = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.delete(`${BASE_URL}user/${id}`, config);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getAllRoles = async (itemPerPage, page, searchBy, searchText) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (searchBy && searchText && searchBy !== "" && searchText !== "") {
        res = await axios.get(
          `${BASE_URL}user?${searchBy}=${searchText}`,
          config
        );
      } else {
        res = await axios.get(`${BASE_URL}user`, config);
      }
      if (res.status === 200) {
        dispatch({
          type: ROLE,
          payload: res.data,
        });
        //getSettings();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      
    }
  };

  const getSettings = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.get(`${BASE_URL}users/findUserLogin`, config);
      if (res.status === 200) {
        dispatch({
          type: SETTINGS,
          payload: res.data,
        });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      
    }
  };

  const updateProfile = async (data) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    try {
      const res = await axios.patch(`${BASE_URL}users`, data, config);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      
    }
  };

  const addSpecialist = async (data) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_URL}specialist`, data, config);
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const editSpecialist = async (data, id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.patch(
        `${BASE_URL}specialist/${id}?redirect_uri=https://app.telerob.com.mx`,
        data,
        config
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const getAllSpecialist = async (isdisabled, id, searchBy, search) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (isdisabled !== undefined && !searchBy && !search) {
        res = await axios.get(
          `${BASE_URL}specialist?disabled=${isdisabled}`,
          config
        );
      } else if (id !== undefined)
        res = await axios.get(
          `${BASE_URL}specialist?associated_user=${id}`,
          config
        );
      else if (searchBy && search && searchBy !== "" && search !== "")
        if (isdisabled !== undefined)
          res = await axios.get(
            `${BASE_URL}specialist?${searchBy}=${search}&disabled=${isdisabled}`,
            config
          );
        else
          res = await axios.get(
            `${BASE_URL}specialist?${searchBy}=${search}`,
            config
          );
      else {
        res = await axios.get(`${BASE_URL}specialist`, config);
      }
      if (res.status === 200) {
    
        dispatch({
          type: SPECIALISTS,
          payload: res.data,
        });

        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getAvailableSpecialists = async (searchBy, search,english) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if(english === undefined)
      res = await axios.get(
        `${BASE_URL}specialist?${searchBy}=${search}&disabled=false&state=1`,
        config
      );
      else
      res = await axios.get(
        `${BASE_URL}specialist?${searchBy}=${search}&disabled=false&state=1&english=${english}`,
        config
      );

      if (res.status === 200) {
        
        dispatch({
          type: SPECIALISTS,
          payload: res.data,
        });

        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const removeSpecialist = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.delete(`${BASE_URL}specialist/${id}`, config);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const getAllSpecialisties = async (searchBy, search) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (searchBy && search && searchBy !== "" && search !== "")
        res = await axios.get(
          `${BASE_URL}medical-speciality?name=${search}`,
          config
        );
      else res = await axios.get(`${BASE_URL}medical-speciality`, config);

      if (res.status === 200) {
        dispatch({
          type: SPECIALITY,
          payload: res.data,
        });
        //getSettings();
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");

      
    }
  };

  const addSpeciality = async (data) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}medical-speciality`,
        data,
        config
      );
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const editSpeciality = async (data, id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.patch(
        `${BASE_URL}medical-speciality/${id}`,
        data,
        config
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const removeSpeciality = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.delete(
        `${BASE_URL}medical-speciality/${id}`,
        config
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const getAllMedicalClasifications = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      res = await axios.get(`${BASE_URL}medical-clasification`, config);

      if (res.status === 200) {
        dispatch({
          type: MEDICAL_CLASIFICATION,
          payload: res.data,
        });
        //getSettings();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getAuthenticatedUser = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      res = await axios.get(`${BASE_URL}auth/me`, config);

      if (res.status === 200) {
        dispatch({
          type: USER,
          payload: res.data,
        });
        //getSettings();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      
      if (error?.response?.data?.statusCode === 401)
      window.location.replace("/auth-login");
    else if (error?.code === "ERR_NETWORK")
      window.location.replace("/error-504");
    }
  };

  const getSpecialist = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;

      res = await axios.get(`${BASE_URL}specialist/${id}`, config);

      if (res.status === 200) {
        dispatch({
          type: SPECIALIST,
          payload: res.data,
        });
        //getSettings();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getMenuBar = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;

      res = await axios.get(`${BASE_URL}infrastruture/menus`, config);

      if (res.status === 200) {
        dispatch({
          type: MENU,
          payload: res.data,
        });
        //getSettings();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      
    }
  };

  const addReportCase = async (data) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_URL}report`, data, config);
      if (res.status === 201) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const editReportCase = async (data, id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.patch(
        `${BASE_URL}report/${id}?redirect_uri=https://telerob.com.mx`,
        data,
        config
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const getAllReportCases = async (searchBy, search) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (searchBy && search && searchBy !== "" && search !== "")
        res = await axios.get(
          `${BASE_URL}report?${searchBy}=${search}`,
          config
        );
      else {
        res = await axios.get(`${BASE_URL}report`, config);
      }
      if (res.status === 200) {
        dispatch({
          type: REPORT_CASE,
          payload: res.data,
        });
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const removeReportCase = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.delete(`${BASE_URL}report/${id}`, config);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const asignReport = async (idReport, idSpec) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      report_id: idReport,
      specialist_id: idSpec,
    };
    try {
      const res = await axios.post(
        `${BASE_URL}assignment-report`,
        data,
        config
      );
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const reAsignReport = async (idReport, idSpec) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      report_id: idReport,
      specialist_id: idSpec,
    };
    try {
      const res = await axios.post(
        `${BASE_URL}assignment-report/reassign`,
        data,
        config
      );
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response?.data?.message,
        errorStatus: error.response?.data?.statusCode,
      };
    }
  };

  const getAssignments = async (searchBy, search) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (searchBy && search && searchBy !== "" && search !== "")
        res = await axios.get(
          `${BASE_URL}assignment-report?${searchBy}=${search}`,
          config
        );
      else {
        res = await axios.get(`${BASE_URL}assignment-report`, config);
      }
      if (res.status === 200) {
      
        dispatch({
          type: ASSIGN,
          payload: res.data,
        });

        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getAllMedicalsCenter = async (searchBy, search) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (searchBy && search && searchBy !== "" && search !== "")
        res = await axios.get(
          `${BASE_URL}medical-center?${searchBy}=${search}`,
          config
        );
      else {
        res = await axios.get(`${BASE_URL}medical-center`, config);
      }

      if (res.status === 200) {
        dispatch({
          type: MEDICALS_CENTER,
          payload: res.data,
        });
        //getSettings();
       return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const addMedicalsCenter = async (data) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_URL}medical-center`, data, config);
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const editMedicalsCenter = async (data, id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.patch(
        `${BASE_URL}medical-center/${id}`,
        data,
        config
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const removeMedicalsCenter = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.delete(`${BASE_URL}medical-center/${id}`, config);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response.data.message,
        errorStatus: error.response.data.statusCode,
      };
    }
  };

  const changeStateAsign = async (idAsign, idSpec, stateN) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      specialist_id: idSpec,
      state: stateN,
    };
    try {
      const res = await axios.put(
        `${BASE_URL}assignment-report/${idAsign}`,
        data,
        config
      );
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response?.data?.message,
        errorStatus: error.response?.data?.statusCode,
      };
    }
  };

  const changeStateAsignIncompleto = async (idAsign, idSpec) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      specialist_id: idSpec,    
    };
    try {
      const res = await axios.patch(
        `${BASE_URL}assignment-report/${idAsign}/incomplete`,
        data,
        config
      );
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      return {
        errorMsg: error.response?.data?.message,
        errorStatus: error.response?.data?.statusCode,
      };
    }
  };

  const getDashboardCardsReportsState = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      res = await axios.get(`${BASE_URL}dashboard/cards`, config);
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getDashboardReportsSpecialist = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      res = await axios.get(
        `${BASE_URL}dashboard/medical_speciality_graph`,
        config
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getDashboardAssigns = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      res = await axios.get(
        `${BASE_URL}dashboard/assignment_state_piegraph`,
        config
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getReportsBySpecialists = async (id) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (id)
        res = await axios.get(
          `${BASE_URL}dashboard/specialist_summary/${id}`,
          config
        );
      else
        res = await axios.get(
          `${BASE_URL}dashboard/specialist_summary`,
          config
        );
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getTopSpecialists = async () => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      res = await axios.get(`${BASE_URL}dashboard/top_specialist/`, config);
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      else if (error?.code === "ERR_NETWORK")
        window.location.replace("/error-504");
      
    }
  };

  const getInformExcel = async (idSpecialist) => {
    const token =
      localStorage.getItem("accessToken") !== null
        ? localStorage.getItem("accessToken").slice(1, -1)
        : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res;
      if (idSpecialist)
        res = await axios.get(
          `${BASE_URL}inform/finance_report?specialist_id=${idSpecialist}`,
          config
        );
      else res = await axios.get(`${BASE_URL}inform/finance_report`, config);
      if (res) {
        if (idSpecialist)
        window.open(`${BASE_URL}inform/finance_report?specialist_id=${idSpecialist}`,'_blank');
        else
        window.open(`${BASE_URL}inform/finance_report`,'_blank');
        
        return res;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401)
        window.location.replace("/auth-login");
      
    }
  };

  const getNotifcationSpecialist =  async (idAssociatedUser)=> {
    const token =
    localStorage.getItem("accessToken") !== null
      ? localStorage.getItem("accessToken").slice(1, -1)
      : null;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    let res;
    if(idAssociatedUser !== undefined)
    res = await axios.get(
      `${BASE_URL}auth/send_email/${idAssociatedUser}`,
      config
    );
   
    if (res.status === 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    if (error?.response?.data?.statusCode === 401)
      window.location.replace("/auth-login");
    else if (error?.code === "ERR_NETWORK")
      window.location.replace("/error-504");
    else 
    return {
      errorMsg: error.response?.data?.message,
      errorStatus: error.response?.data?.statusCode,
    }; 
  }
  }

  return (
    <GeneralContext.Provider
      value={{
        users: state.users,
        addUsers,
        editUsers,
        getAllUsers,
        removeUser,
        getAuthenticatedUser,
        getIdUser,
        changeRoleToUser,
        user: state.user,
        getSpecialist,
        specialist: state.specialist,
        addSpecialist,
        editSpecialist,
        getAllSpecialist,
        getAvailableSpecialists,
        removeSpecialist,
        specialists: state.specialists,
        getAllSpecialisties,
        specialities: state.specialities,
        addSpeciality,
        editSpeciality,
        removeSpeciality,
        getAllMedicalClasifications,
        medicals_clasifications: state.medicals_clasifications,
        settings: state.settings,
        getSettings,
        updateProfile,
        getMenuBar,
        menu: state.menu,
        report_cases: state.report_cases,
        addReportCase,
        editReportCase,
        getAllReportCases,
        removeReportCase,
        asignReport,
        reAsignReport,
        getAssignments,
        assignments: state.assignments,
        getAllMedicalsCenter,
        addMedicalsCenter,
        editMedicalsCenter,
        removeMedicalsCenter,
        medicals_centers: state.medicals_centers,
        changeStateAsign,
        getDashboardCardsReportsState,
        getDashboardAssigns,
        getDashboardReportsSpecialist,
        getReportsBySpecialists,
        getTopSpecialists,
        getInformExcel,
        changeStateAsignIncompleto,
        getNotifcationSpecialist
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralState;
