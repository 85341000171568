import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import RSelect from "./ReactSelect";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CircularProgress, Stack } from "@mui/material";

export default function CustomDialog(props) {
  const {
    data,
    title,
    onSave,
    onCancel,
    open,
    operation,
    requiredCampos,
    disabledCampos,
    onChangeBolean,
    inputs_needs_tilde,
    loading,
  } = props;

  const onChangeInput = (ev, name) => {
    
    if (name === "roles") setDataNew({ ...dataNew, realmRoles: [ev.value] });
    else if (name === "prioridad")
      setDataNew({ ...dataNew, priority: [ev.value] });
    else if (name === "centro_medico")
      setDataNew({ ...dataNew, medical_center_id: [ev.value] });
    else if (name === "especialidad")
      setDataNew({ ...dataNew, medical_speciality_id: [ev.value] });
    else if (name === "medical_specialities") {
      setDataNew({ ...dataNew, medical_specialities: ev.target.value });
    } else if (typeof data[ev.target.name] === "string")
      setDataNew({ ...dataNew, [ev.target.name]: ev.target.value });
    else if (typeof data[ev.target.name] === "number")
      setDataNew({ ...dataNew, [ev.target.name]: ev.target.value });
    else if (typeof data[ev.target.name] === "boolean") {
      setDataNew({ ...dataNew, [ev.target.name]: ev.target.checked });
      if (onChangeBolean) onChangeBolean(ev.target.checked);
    }

    
  };

  const [dataNew, setDataNew] = React.useState(data);
  

  const isDisabledButton = () => {
    return requiredCampos.findIndex((item) =>
      document.getElementById(item)
        ? document.getElementById(item).value === "" 
        : item === ""
    );
  };

  React.useEffect(() => setDataNew(data), [data]);

  const needsTilde = (input_key) => {
    if (inputs_needs_tilde) {
      const found = inputs_needs_tilde.find((item) => item.key === input_key);

      if (found !== undefined) return found?.traduce;
      else return "";
    } else return "";
  };

  return (
    <Dialog className={open ? " opacity-100" : " opacity-0"} 
    id="customDialog" key={'customDialog'}
     open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          className="d-flex row justify-content-between"
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "80%" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            {Object.entries(data).map((item) =>
              item[1] !== undefined &&
              item[0] !== "id" &&
              item[0] !== "ref_number" ? (
                item[0] === "medical_specialities" ? (
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">
                      Especialidad
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={
                        dataNew["medical_specialities"] === undefined ||
                        dataNew["medical_specialities"]?.length === 0
                          ? []
                          : dataNew["medical_specialities"]
                      }
                      onChange={(ev) =>
                        onChangeInput(ev, "medical_specialities")
                      }
                      input={<OutlinedInput label="Especialidad" />}
                    >
                      {data["specialities"].map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : typeof item[1] === "string" && item[0] !== "id" ? (
                  <TextField 
                    disabled={
                      operation === "Edit" &&
                      disabledCampos.findIndex((itemF) => itemF === item[0]) >
                        -1
                    }
                    
                    defaultValue={item[1]}
                    key={item[0]}
                    sx={{width: Number.isNaN(Number(item[1])) ? "100%" : '50%'  }}
                    label={
                      needsTilde(item[0]) !== ""
                        ? needsTilde(item[0])
                        : //convertir de centro_medico a Centro Medico, por ejemplo
                        item[0].includes("_")
                        ? item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].indexOf("_")) +
                          " " +
                          item[0]
                            .substring(
                              item[0].indexOf("_") + 1,
                              item[0].indexOf("_") + 2
                            )
                            .toUpperCase() +
                          item[0].substring(
                            item[0]?.indexOf("_") + 2,
                            item[0]?.length
                          )
                        : item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0]?.length)
                    }
                    name={item[0]}
                    id={item[0]}
                    variant="outlined"
                    onChange={onChangeInput}
                  />
                ) : typeof item[1] === "number" &&
                  item[0] !== "prioridad" &&
                  item[0] !== "medical_clasification_id" ? (
                  <TextField
                    type="number"
                    fullWidth
                    key={item[0]}
                    label={
                      needsTilde(item[0]) !== ""
                        ? needsTilde(item[0])
                        : //convertir de centro_medico a Centro Medico, por ejemplo
                        item[0].includes("_")
                        ? item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].indexOf("_")) +
                          " " +
                          item[0]
                            .substring(
                              item[0].indexOf("_") + 1,
                              item[0].indexOf("_") + 2
                            )
                            .toUpperCase() +
                          item[0].substring(
                            item[0].indexOf("_") + 2,
                            item[0].length
                          )
                        : item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].length)
                    }
                    name={item[0]}
                    id={item[0]}
                    variant="outlined"
                    onChange={onChangeInput}
                  />
                )  : typeof item[1] === "boolean" ? (
                  <FormControlLabel
                  disabled={operation === "Edit" &&
                  disabledCampos.findIndex((itemF) => itemF === item[0]) >
                    -1}
                    sx={{
                      display: "block",
                      marginTop: "10px",
                    }}
                    key={item[0]}
                    control={
                      <Switch
                        checked={dataNew[item[0]]}
                        onChange={onChangeInput}
                        name={item[0]}
                        color="primary"
                      />
                    }
                    label={
                      needsTilde(item[0]) !== ""
                        ? needsTilde(item[0])
                        : //convertir de centro_medico a Centro Medico, por ejemplo
                        item[0].includes("_")
                        ? item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].indexOf("_")) +
                          " " +
                          item[0]
                            .substring(
                              item[0].indexOf("_") + 1,
                              item[0].indexOf("_") + 2
                            )
                            .toUpperCase() +
                          item[0].substring(
                            item[0].indexOf("_") + 2,
                            item[0].length
                          )
                        : item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].length)
                    }
                  />
                ) : typeof item[1] === "object" &&
                  item[1].length > 0 &&
                  item[0] !== "specialities" ? (
                  <RSelect
                    className="col-10 col-lg-5 col-sm-10 mb-2 mt-1"
                    defaultValue={
                      operation === "Edit"
                        ? {
                            label: item[1][0].label,
                            value: item[1][0].value,
                          }
                        : "Seleccione"
                    }
                    placeholder={
                      needsTilde(item[0]) !== ""
                        ? needsTilde(item[0])
                        : //convertir de centro_medico a Centro Medico, por ejemplo
                        item[0].includes("_")
                        ? item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].indexOf("_")) +
                          " " +
                          item[0]
                            .substring(
                              item[0].indexOf("_") + 1,
                              item[0].indexOf("_") + 2
                            )
                            .toUpperCase() +
                          item[0].substring(
                            item[0].indexOf("_") + 2,
                            item[0].length
                          )
                        : item[0].substring(0, 1).toUpperCase() +
                          item[0].substring(1, item[0].length)
                    }
                    key={item[0]}
                    name={item[0]}
                    options={item[1]}
                    onChange={(ev) => onChangeInput(ev, item[0])}
                  />
                ) : null
              ) : (
                <div></div>
              )
            )}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className="button-Secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            disabled={isDisabledButton() > -1 && operation === "New"}
            className="button-Primary"
            onClick={() => {
              onSave(dataNew);
            }}
          >
            Guardar
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: "#eeeeee",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
