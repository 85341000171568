export const LOGIN = "LOGIN";
export const ROLE = "ROLE";
export const REGISTER = "REGISTER";
export const MENU = "MENU";
export const MFA = "MFA";
export const FORGOT = "FORGOT";
export const USER = "USER";
export const USERS = "USERS";
export const SPECIALISTS= "SPECIALISTS";
export const SPECIALIST = "SPECIALIST";
export const SPECIALITY = "SPECIALITY";
export const MEDICAL_CLASIFICATION = "MEDICAL_CLASIFICATION";
export const REPORT_CASE = "REPORT_CASE";
export const ASSIGN = "ASSIGN";
export const INTERGRATION = "INTERGRATION";
export const SETTINGS = "SETTINGS";
export const PROVIDERS = "PROVIDERS";
export const MEDICALS_CENTER = "MEDICALS_CENTER";
