import React from "react";
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorImage from "../../Assets/images/jsTree/error-504.svg";

const Error504Modern = () => {
  return (
    <Box className="nk-block-middle wide-md mx-auto">
      <Box className="nk-error-ld text-center">
        <img className="nk-error-gfx" src={ErrorImage} alt="error" />
        <div className="wide-xs mx-auto">
          <h3 className="nk-error-title">Opps, hay problemas de conexión con el servidor</h3>
          <p className="nk-error-text">
            Sentimos el inconveniente. Estamos trabajando activamente para restaurar el servicio. Espere unos minutos y vuelva a intentarlo.
          </p>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <Button size="lg" className="mt-2 button-Primary">
              Regresar al inicio
            </Button>
          </Link>
        </div>
      </Box>
    </Box>
  );
};
export default Error504Modern;
