import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import {
  Route,
  BrowserRouter as Router,
  Link,
  Redirect,
} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import "../../Modules/Oficina_Home.css";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Validaciones from "../../Services/Utils/Validaciones";
import New_Table_Customized from "../Components/New_Table_Customized";
import exportFromJSON from "export-from-json";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import NavBar_Bootstrap from "../Components/NavBars/NavBar_Bootstrap";
import imageNotFound from "../../Assets/undraw_no_data_re_kwbl.svg";
import { Box, Button, CircularProgress } from "@mui/material";
import RSelect from "../Components/ReactSelect";

export default function TempleteReportes(props) {
  const [filtrerBy, setfiltrerBy] = useState(props.optionsHowToSee[0].value);
  const [dataForLoad, setdataForLoad] = useState([]);
  const [viewSeleted, setviewSeleted] = useState("");
  const [loading, setloading] = useState("hide");
  const [justloading, setjustloading] = useState("hide");
  const [orderSelected, setorderSelected] = useState({});
  const [disabledButton, setdisabledButton] = useState(props.isButtonDisable);
  const [search, setsearch] = useState("");
  const [rowsPerPage, setrowsPerPage] = useState(props.rowsPerPage);
  const [page, setpage] = useState(0);
  const [onSearchText, setSearchText] = useState(props.optionsHowToSee[0].value);

  const handleChangePage = async (event, newPage) => {
    setpage(newPage);
    props.onChangePage(page);
  };

  const handleChangeRowsPerPage = async (event) => {
    setrowsPerPage(event.target.value);
    setpage(0);

    props.onChangeRowPerPage(rowsPerPage);
  };

  function getTypeLogsNameById(id) {
    var founded = false;
    var i = 0;
    var nameFounded = "";

    while (i < props.optionsHowToSee.length && founded == false) {
      if (props.optionsHowToSee[i].id == id) {
        founded = true;
        nameFounded = props.optionsHowToSee[i].name;
      }
      i++;
    }
    return nameFounded;
  }


  const onOrderSelected = (id) => {
    if (props.onOrderSelect !== undefined) {

      setorderSelected(id);
      setdisabledButton(false);

      props.onOrderSelect(id);
    }
  };

  const onSearch = () => { 
      
    props.onSearch(filtrerBy, search);
    setsearch("");
  };

  const onChangeSearch = (ev) => {
    const val = ev.target.value;
    setsearch(val);    
    if(val === "")
    props.onSearch(filtrerBy, "");
    //onSearch(val);
  };

  const onActionText = (e) => {
    setfiltrerBy(e.value);
    if(document.getElementById('search') !== null)
     document.getElementById('search').value = "" ;
    props.onSearch("", "");
    setsearch("");
  };

  const exportTable = () => {
    const fileName = props.nameReporte;
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: props.dataLoad, fileName, exportType });
  };
  
  useEffect(()=>{

  },[props.dataLoad])

  return (
    <div className=" d-flex justify-content-center bg-lightBlue col-lg-11-mine h-100 ms-auto p-1">
      <div className="col-12 col-lg-12 col-sm-12 pt-3 align-content-center d-flex justify-content-center ">
       
          <div className="row mt-3 w-100 h-100 mb-5 align-content-center d-flex justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12  ps-0 pe-0 align-content-center d-flex justify-content-center">
              <div className="w-100 align-content-center d-flex justify-content-center">
                <div className="col-12 mt-4 card border-none bg-transparent">
                  <div className="card-header row pe-4 align-content-between align-items-center d-flex justify-content-between">
                    <div className="col-8 col-lg-7 col-sm-8">
                      <h3 className="mt-auto mb-auto">
                        {props.nameReporte.substring(0, 1).toUpperCase() +
                          props.nameReporte.substring(
                            1,
                            props.nameReporte.length
                          )}
                      </h3>
                    </div>
                    <div className="col-4 col-lg-5 col-sm-4 d-flex justify-content-end">
                      {props.buttonGeneral && props.buttonGeneral !== null ? (
                        <Button
                          variant="contained"
                          onClick={props.buttonGeneral?.onClick}
                          startIcon={<AddIcon />}
                          disabled={props.buttonGeneral?.disabled !== undefined ? props.buttonGeneral?.disabled : false}
                          className={props.buttonGeneral?.className}
                        >
                          {props.buttonGeneral?.value}
                        </Button>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>

                  <div className="card-body text-center border rounded-2 bg-white">
                    <div className="row p-0  align-content-between d-flex justify-content-between">
                      <div className="col-9 col-lg-8 col-sm-9 p-1 row ms-1 align-content-start d-flex justify-content-start">
                        <div className="col-6 col-lg-3 col-sm-6 p-1">
                         
                          <RSelect
                            defaultValue={{
                              label: props.optionsHowToSee[0].label,
                              value: props.optionsHowToSee[0].value,
                            }}
                            options={props.optionsHowToSee}
                            placeholder="Filtrar"
                            onChange={(e) => onActionText(e)}
                          />
                        </div>
                        <div className="col-6 col-lg-3 col-sm-6 p-1 ms-lg-2 ps-2">
                          {
                            filtrerBy === 'state' 
                            ?
                            <RSelect                           
                            options={props.statusOptions}
                            placeholder="Estados"
                            onChange={(e) => { 
                              const val = e.value;
                              setsearch(val);
                             
                              if(val === "")
                              props.onSearch(filtrerBy, "");
                            else props.onSearch(filtrerBy, val);}}
                          />
                            :
                            <div className="input-group input-group-search">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              onChange={onChangeSearch}
                              id="search"
                              placeholder="Buscar: "
                            />
                            <IconButton
                              className="input-group-text button-Primary-Icon"
                              aria-label="delete"
                              id="button-search"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onSearch();
                              }}
                            >
                              <SearchIcon />
                            </IconButton>
                            
                          </div>

                          }
                         
                        </div>
                      </div>
                      <div className="col-3 col-lg-1 col-sm-3  p-0 align-content-center align-items-center d-flex justify-content-center ">
                          <IconButton
                            className="input-group-text  button-Primary-Icon "
                            aria-label="delete"
                            id="button-search"
                            onClick={props.export}
                          >
                            <FileUploadIcon />
                          </IconButton>
                          {
                              props.refresh 
                              ?
                              <IconButton
                              className="input-group-text button-Primary-Icon"
                              aria-label="delete"
                              id="button-search"
                              onClick={(ev) => {
                                props.refresh();
                              }}
                            >
                              <RefreshIcon />
                            </IconButton>
                               :
                               <div/>

                            }
                      </div>
                    </div>

                    {props.loading 
                    ?
                    <Box sx={{ m: 1, position: 'relative', minHeight: 200 }} >                   
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#3c78bc',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                   </Box>
                    :
                    props.dataLoad === null || props.dataLoad === undefined || props.dataLoad.length === 0 || props.dataLoad.length === undefined ? (
                      <div className="h-100">
                        <img
                          className="imgPerfilUserProfile ms-auto me-auto"
                          src={imageNotFound}
                        />
                        <h6 className="text-center mt-3">
                          Sin información que mostrar
                        </h6>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <New_Table_Customized
                          columns={props.columnsValue}
                          onElementSelected={onOrderSelected}
                          dataLoad={props.dataLoad}
                          idsCampoTypeObject={props.valuesForColumnColumn}
                          changeEnabled={props.changeEnabled}
                          buttonsArray={props.buttonsArray}
                          arrayOfStatus={props.arrayOfStatus}
                          page={page}
                          rowsPerPage={props.rowsPerPage}
                        />
                        <TablePagination
                          className="mt-3"
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={props.totalItems}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </div>
  );
}

/*
 <FormControl
                            color="info"
                           
                            variant="standard"
                            fullWidth
                          >
                            <InputLabel id="demo-simple-select-label">
                              Filtrar por
                            </InputLabel>
                            <Select
                              color="info"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filtrerBy}
                              onChange={onChangeFiltrered}
                            >
                              {props.optionsHowToSee.map((opt) =>
                                props.nameReporte === "ganancias" ? (
                                  <MenuItem id={opt["name"]} value={opt["id"]}>
                                    {opt["name"]}
                                  </MenuItem>
                                ) : (
                                  <MenuItem id={opt} value={opt}>
                                    {opt}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        
*/
