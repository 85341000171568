import logo from "./logo.svg";
import "./App.css";
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import Error404Modern from "./Components/Containers/404-modern";
import { BASE_URL } from "./Services/Utils/baseUrl";
import axios from "axios";
import Login from "./Modules/Authentication/Login";
import { FRONT_URL } from "./Services/Utils/baseUrl";
import Forgot_Password from "./Modules/Authentication/Forgot_Password";
import ReporteAssignacion from "./Modules/Assign/ReporteAssignacion";
import UserList from "./Modules/Users/UserList";
import SpecialistList from "./Modules/Specialists/SpecialistList";
import Edit_Profile from "./Modules/Edit_User/Edit_Profile";
import AuthContext from "./Contexts/auth-context/AuthContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import SpecialitiesList from "./Modules/Specialities/Specialities_List";
import MedicalsCenterList from "./Modules/MedicalsCenter/MedicalsCenterList";
import GeneralContext from "./Contexts/general-context/GeneralContext";
import { Dashboard } from "./Modules/Dashboard/Dashboard";
import { RedirectAs404 } from "./Services/Utils/Validaciones";
import Error504Modern from "./Components/Containers/504-modern";

const App = () => {
  const [authed, setAuthed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { userAuth, getUserAuth, cleanUserAuth } =
    React.useContext(AuthContext);
  const { getAllSpecialisties, getAllSpecialist, getAllMedicalsCenter } =
    React.useContext(GeneralContext);
  

  const PrivateRoute = ({ exact, component: Component, ...rest }) => (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        authed ? (
          <Component {...props} {...rest}></Component>
        ) : (
          <Navigate to={`${FRONT_URL}/auth-login`}></Navigate>
        )
      }
    ></Route>
  );

  useEffect(() => {
    const resp = getUserAuth().then((next) => {         
      setLoading(false);
      setAuthed(next);
    });
  }, []);

  const isAuth = () => {
    const vak = getUserAuth().then((next) => {
     
      setAuthed(next);
      return;
    });
   
    return vak;
  };

  const onLogin = async () => {
    await getUserAuth();
  
    await getAllSpecialisties();
    await getAllSpecialist();
    await getAllMedicalsCenter();
    setAuthed(true);
  };

  const onLogout = async () => {
    setAuthed(false);
    localStorage.setItem("username", "");
    await cleanUserAuth();
  };

  return loading ? (
    <Backdrop
      className="glass-panel-loading"
      sx={{ color: "#ffff", zIndex: 105 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <BrowserRouter>
      <Routes>
        {/* Auth Pages */}
        <Route
          exact
          path={`/auth-login`}
          element={<Login onLogin={onLogin} />}
        ></Route>
        <Route
          exact
          path={`/auth/reset/pass`}
          element={<Forgot_Password />}
        ></Route>

        {/*Error Pages*/}
        <Route exact path={`/error-404`} element={<Error404Modern />}></Route>
        <Route exact path={`/error-504`} element={<Error504Modern />}></Route>
        <Route
          path={`/assign`}
          element={
            authed ? (
              <ReporteAssignacion
                onLogout={onLogout}
                user={userAuth}
              ></ReporteAssignacion>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
        <Route
          path={`/specialities`}
          element={
            authed ? (
              <SpecialitiesList
                onLogout={onLogout}
                user={userAuth}
              ></SpecialitiesList>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
        <Route
          path={`/medical-centers`}
          element={
            authed ? (
              <MedicalsCenterList
                onLogout={onLogout}
                user={userAuth}
              ></MedicalsCenterList>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
        <Route
          path={`/users`}
          element={
            authed ? (
              <UserList onLogout={onLogout} user={userAuth}></UserList>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>

        <Route
          path={`/profile`}
          element={
            authed ? (
              <Edit_Profile
                onLogout={onLogout}
                userAuth={userAuth}
              ></Edit_Profile>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
        <Route
          path={`/specialists`}
          element={
            authed ? (
              <SpecialistList onLogout={onLogout}></SpecialistList>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
        <Route
          path={`/`}
          element={
            authed ? (
              <Dashboard onLogout={onLogout} userAuth={userAuth}></Dashboard>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
        <Route
          path="*"
          element={<Navigate to={`/error-404`}></Navigate>}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;

//<PrivateRoute exact path="" component={Layout}></PrivateRoute>
/*
<Route
          path={`/specialists`}
          element={
            authed ? (
              <SpecialistList></SpecialistList>
            ) : (
              <Navigate to={`/auth-login`}></Navigate>
            )
          }
        ></Route>
*/
