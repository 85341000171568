import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Route,
  BrowserRouter as Router,
  Link,
  Redirect,
  Switch,
  withRouter,
  useHistory,
} from "react-router-dom";

import UserIcon from "../../Assets/happy-smiling-young-man-3d-260nw-2128644164.png";
import New_Table_Customized from "../../Components/Components/New_Table_Customized";
import imageNotFound from "../../Assets/undraw_no_data_re_kwbl.svg";
import {
  faCheckCircle,
  faWindowClose,
  faCopy,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertDialog from "../../Components/Components/AlertDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import NavBar_Bootstrap from "../../Components/Components/NavBars/NavBar_Bootstrap";
import Alert_MUI from "../../Components/Components/Alert_MUI";
import UserInfo_Card from "./UserInfo_Card";
import PasswordChange_Card from "./PasswordChange_Card";
import ContactChange_Card from "./ContactChange_Card";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import { BASE_URL } from "../../Services/Utils/baseUrl";
import AuthContext from "../../Contexts/auth-context/AuthContext";
import { NextWeek } from "@mui/icons-material";

export default function Edit_Profile(props) {
  const {
    getAllSpecialist,
    specialists,
    getAssignments,
    changeStateAsign,
    assignments,
    changeStateAsignIncompleto
  } = React.useContext(GeneralContext);
  const { userAuth } = props;
  const { getUserAuth, cleanUserAuth} = React.useContext(AuthContext);
  const { user } = props;
  const [loading, setLoading] = React.useState(true);
  const [notifications, setNotific] = React.useState(0);
  const [userOldInfo, setUserOld] = React.useState({});
  const [type, setType] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [visibility, setVisibility] = React.useState(false);
  const [visibilityAlertConfirm, setVisibAlertConf] = React.useState(false);
  const arrayOfStatusAssigns = [
    {
      name: 0,
      color: "info",
      nameTraduced: "Asignado",
    },
    {
      name: 1,
      color: "info",
      nameTraduced: "Aceptado",
    },
    {
      name: 2,
      color: "error",
      nameTraduced: "Rechazado",
    },
    {
      name: 3,
      color: "error",
      nameTraduced: "Expirado",
    },
    {
      name: 4,
      color: "success",
      nameTraduced: "Completado",
    },
    {
      name: 5,
      color: "warning",
      nameTraduced: "Reasignado",
    },
  ];

  const onSaveSpecialist = ()=>{
  getSpecilista();
}

  const getSpecilista = async (user) => {
    setLoading(true);    

    //await getUserAuth();
    if (user !== undefined) {
      setUserOld({
        user_id: userAuth?.user_id,
        preferred_username: userAuth?.preferred_username,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.firstName + " " + user.lastName,
        given_name: user.firstName,
        family_name: user.lastName,
        email: user.email,
        email_verified: userAuth?.email_verified,
      });
      setLoading(false);   
      
    } else {
      if(localStorage.getItem('username') && localStorage.getItem('username').toLowerCase() === userAuth.preferred_username?.toLowerCase()){
        setUserOld({
          user_id: userAuth?.user_id,
          preferred_username: userAuth?.preferred_username,
          firstName: userAuth?.given_name,
          lastName: userAuth?.family_name,
          name: userAuth?.given_name + " " + userAuth?.family_name,
          given_name: userAuth?.given_name,
          family_name: userAuth?.family_name,
          email: userAuth?.email,
          email_verified: userAuth?.email_verified,
        });
  
      if (userAuth?.realm_access?.roles.includes("specialist") ) {
         await getAllSpecialist(undefined, userAuth?.user_id).then((next)=>{
           getAssignments("specialist_id", next[0].id);
          setUserOld({
            ...userOldInfo,
            user_id: userAuth?.user_id,
            id: next[0].id,
            preferred_username: userAuth?.preferred_username,
            firstName: next[0].firstName,
            lastName: next[0].lastName,
            name: next[0].firstName + " " + next[0].lastName,
            email: next[0].email,
            phone: next[0].phone_number,
            given_name: next[0].firstName,
            family_name: next[0].lastName,
            email_verified: userAuth?.email_verified,
            status: next[0].state,
            specialists: NextWeek,
            medical_specialists: next[0].medical_specialities,
          });
          
          setLoading(false);
        });
        
      } else {
        setLoading(false);
      }
      }
     
    }   
     
  };

  const getCurrentUser = async () => {
    setUserOld(user);
    if (user.realm_access.roles.includes("specialist"))
      getAllSpecialist(undefined, user.user_id);
    setLoading(false);
  };

  React.useEffect(() => {
    getSpecilista();
  }, [userAuth]);

  const onChangeState = async(new_state, idAsign) => {
    let resp;
    if(new_state !== 4)
     resp = await changeStateAsign(idAsign, userOldInfo.id, new_state);
    else 
    resp = await changeStateAsignIncompleto(idAsign, userOldInfo.id );
    if (resp?.errorStatus === undefined) {
      setType("success");
      setMsg("Cambio de estado de la asignacion exitoso");
      setTitle("Operación exitosa");
      setVisibility(true);
      getSpecilista();
    } else {
      setType("error");
      setMsg("Ha ocurrido un error");
      setTitle("Error");
      setVisibility(true);
    }
  };

  return (
    <div
      className="container-fluid  bg-lightBlue mt-auto mb-auto h-100  w-100 "
      id="body-pd"
    >
       <Backdrop
          className="glass-panel-loading"
          sx={{ color: "#ffff", zIndex: 105 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      <div className="row h-100 pb-5">
       
        <div className="col-1 col-lg-1 col-sm-1 ">
          <NavBar_Bootstrap
            itemActive={1}
            title={"Mi cuenta"}
            totalNotif={notifications}
            onLogout={props.onLogout}
          />
        </div>
        <div className="col-12 col-lg-11 col-sm-12 ms-auto mt-5 pt-3">
          <Alert_MUI
            color={type}
            title={title}
            msg={msg}
            open={visibility}
            onClose={() => {
              setVisibility(false);
            }}
          />
          <div
            className="row bg-transparent justify-content-between d-flex
           align-content-between h-100"
          >
            <div className="col-lg-3  col-sm-12 col-12 p-2 pt-4 pb-0 ">
              <UserInfo_Card
                onChange={(user)=>getSpecilista(user)}
                id={userOldInfo["id"]}
                foto={null}
                iconSustitute={faUser}
                username={userOldInfo["preferred_username"]}
                emailVerf={userOldInfo["email_verified"]}
                fullname={userOldInfo["name"]}
                sponsor={userOldInfo["sponsor"]}
                email={userOldInfo["email"]}
                telephone={specialists[0]?.phone_number}
                backcolor={"white"}
                imageColor={"#03b4cf"}
                stadistics1_text={"Referidos"}
                stadistics1_number={0}
                stadistics2_text={"Ganancias"}
                stadistics2_number={userOldInfo["available_gain"]}
                has_2fa_Prop={userOldInfo.has_2fa}
                onChangeTwoFactor={getCurrentUser}
                isSpecil={userAuth?.realm_access?.roles?.includes("specialist")}
                status={userOldInfo["status"]}
              />
            </div>
            <div className="col-lg-9 col-sm-12 col-12 p-2 pt-0 h-100">
              <div className="card border-none bg-transparent m-0 w-100 p-0 ">
                <div className="card-header w-100 p-0 m-0 ">
                  <ContactChange_Card
                    isSpecil={userAuth?.realm_access?.roles?.includes(
                      "specialist"
                    )}
                    id={userOldInfo["user_id"]}
                    phone={userOldInfo["phone"]}
                    email={userOldInfo["email"]}
                    firstName={userOldInfo["given_name"]}
                    lastName={userOldInfo["family_name"]}
                    onChange={(user)=>getSpecilista(user)}
                    specialists={userOldInfo["specialists"]}
                    medical_specialists={userOldInfo["medical_specialists"]}
                    onSaveSpecialist={onSaveSpecialist}
                  />
                </div>
                {userAuth?.realm_access?.roles?.includes("specialist") ? (
                  <div className="card-body w-100 p-0 mt-3">
                    {assignments?.length === 0 ? (
                      <div />
                    ) : (
                      <div className="card shadow w-100 rounded-15 p-2">
                        <New_Table_Customized
                          columns={columnsAssigns}
                          dataLoad={assignments}
                          arrayOfStatus={arrayOfStatusAssigns}
                          onElementSelected={() => {}}
                          page={0}
                          buttonsArray={[
                            {
                              id: "resp-button",
                              label: "Aceptar",
                              functionOnClick: (item) =>
                                onChangeState(1, item.report.id),
                              className: "button-Secondary",
                              disabled: () => {
                                return false;
                              },
                              inState: 0,
                            },
                            {
                              id: "resp-button",
                              label: "Rechazar",
                              functionOnClick: (item) =>
                                onChangeState(2, item.report.id),
                              className: "button-Error-Outlined",
                              disabled: () => {
                                return false;
                              },
                              inState: 0,
                            },
                            {
                              id: "resp-button",
                              label: "Finalizar",
                              functionOnClick: (item) =>
                                onChangeState(4, item.report.id),
                              className: "button-Success-Outlined",
                              disabled: () => {
                                return false;
                              },
                              inState: 1,
                            },
                            {
                              id: "resp-button",
                              label: "Incompleto",
                              functionOnClick: (item) =>
                                onChangeState(4, item.report.id),
                              className: "button-Warning-Outlined",
                              disabled: () => {
                                return false;
                              },
                              inState: 1,
                            },
                          ]}
                          rowsPerPage={100}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

let columnsAssigns = [
  {
    id: "assignment_date",
    label: "Fecha Asignacion",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "report",
    label: "Estudio Realizado",
    minWidth: 170,
    align: "center",
    isObject: true,
    subitem_show: {
      name: "study_realized",
      isArray: false,
      key: "study_realized",
    },
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "report",
    label: "Nombre del paciente",
    minWidth: 170,
    align: "center",
    isObject: true,
    subitem_show: {
      name: "patient_name",
      isArray: false,
      key: "patient_name",
    },
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "state",
    label: "Estado asignacion",
    minWidth: 170,
    align: "center",
    isObject: true,
    format: (value) => value.toLocaleString("en-US"),
  },
];
/*
<div className="card-body w-100 p-0 mt-3">
                  <PasswordChange_Card />
                </div>
*/
