import React from "react";
import NavBar_Bootstrap from "../../Components/Components/NavBars/NavBar_Bootstrap";
import { alpha, useTheme } from "@mui/material/styles";
import { Chart } from "../../Components/Components/ChartJs";
import GeneralContext from "../../Contexts/general-context/GeneralContext";
import imageNotFound from "../../Assets/undraw_no_data_re_kwbl.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import RSelect from "../../Components/Components/ReactSelect";
import New_Table_Customized from "../../Components/Components/New_Table_Customized";

const dataCardsReportState = {
  OPEN: ["Info", "Reportes Abiertos", "book-open"],
  FINALIZED: ["Success", "Reportes Terminados", "check-circle"],
  EXPIRED: ["Danger", "Reportes Expirados", "alarm-exclamation"],
  UNAVAILABLE: ["Warning", "Reportes No disponibles", "task-x"],
};

const assigmentsEnum = {
  ASSIGNED: "Asignados",
  ACCEPTED: "Aceptados",
  REFUSED: "Rechazados",
  EXPIRED: "Expirados",
  COMPLETED: "Completados",
  REASSIGNED: "Reasignados",
};

export const Dashboard = (props) => {
  const {
    getDashboardCardsReportsState,
    getDashboardAssigns,
    getDashboardReportsSpecialist,
    getReportsBySpecialists,
    getTopSpecialists,
    getAllSpecialist,
    getInformExcel,
  } = React.useContext(GeneralContext);
  const [cardsRepState, setCardsRepState] = React.useState([]);
  const [assigns, setAssigns] = React.useState([]);
  const [repSpecialist, setRepSpecialist] = React.useState([]);
  const [chartOptionsBarEspec, setchartOptionsBarEspec] = React.useState({});
  const [chartOptionsPieAssigns, setchartOptionsPieAssigns] = React.useState(
    {}
  );
  const [chartSeriesBar, setchartSeriesBar] = React.useState([]);
  const [chartSeriesPie, setchartSeriesPie] = React.useState([]);
  const [totalsReports, setTotalReports] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [loadingR, setLoadingR] = React.useState(false);
  const [reportsBySpecialist, setReportsBySpecialist] = React.useState([]);
  const [topSpecialist, setTopSpecial] = React.useState([]);
  const [specialList, setSpecialList] = React.useState([]);
  const [specialSelect, setSepecialSelect] = React.useState(-1);

  React.useEffect(() => {
    getDashboardCardsReportsState()
      .then((cardsInfo) => {
      
        setCardsRepState(cardsInfo);
        const total = cardsInfo.reduce(
          (acum, current) => acum + Number(current.count),
          0
        );
       
        setTotalReports(total);

        getDashboardReportsSpecialist().then((infoEspecialis) => {
        
          const countSpecialist = infoEspecialis.map((item) => {
            return item.count;
          });
          const namesSpecialist = infoEspecialis.map((item) => {
            return item.medical_speciality;
          });
          setLoading(false);
          setchartOptionsBarEspec({
            chart: {
              background: "transparent",
              stacked: false,
              toolbar: {
                show: false,
              },
              type: "bar",
            },

            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 1,
              type: "solid",
            },
            grid: {
              strokeDashArray: 2,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            legend: {
              show: false,
            },
            plotOptions: {
              bar: {
                columnWidth: "40px",
              },
            },
            stroke: {
              colors: ["transparent"],
              show: true,
              width: 2,
            },

            xaxis: {
              axisBorder: {
                show: true,
              },
              axisTicks: {
                show: true,
              },
              categories: namesSpecialist,
              labels: {
                offsetY: 5,
              },
            },
          });
          setchartSeriesBar([{ data: countSpecialist }]);
          setRepSpecialist([countSpecialist, namesSpecialist]);

          getDashboardAssigns().then((infoAssigns) => {
           
            const countAssigns = infoAssigns.map((item2) => {
              return Number(item2.count);
            });
            const namesAssigns = infoAssigns.map((item3) => {
              return assigmentsEnum[
                item3.report_assignment_state
              ].toUpperCase();
            });

            setchartOptionsPieAssigns({
              chart: {
                width: "100%",
                type: "pie",
              },
              labels: namesAssigns,

              plotOptions: {
                pie: {
                  dataLabels: {
                    offset: -5,
                  },
                },
              },

              legend: {
                show: true,
              },
            });
            setchartSeriesPie(countAssigns);
            setAssigns([countAssigns, namesAssigns]);

            getAllSpecialist(false, undefined, "", "").then((next) => {
              const specialistList = next.map((itemSpe) => {
                return {
                  label: itemSpe.firstName + " " + itemSpe.lastName,
                  id: itemSpe.id,
                };
              });
              setSpecialList(specialistList);
            });

            getTopSpecialists().then((next) => {
              setTopSpecial(next);
            });
          });
        });
      })
      .catch((error) => {
       
      });
  }, []);

  const reloadSpecialists = (id) => {
    getReportsBySpecialists(id).then((next) => {
      setReportsBySpecialist(next);
      setLoadingR(false);
    });
  };

  const onSelectSpecialist = (ev) => {   
    setLoadingR(true);

    if(ev)
    setSepecialSelect(ev.id);
    else
      setSepecialSelect(-1);
    if (ev !== null) reloadSpecialists(ev.id);
  };

  return (
    <div
      className="container-fluid  bg-lightBlue  h-100 w-100 p-0"
      id="body-pd"
    >
      <Backdrop
        className="glass-panel-loading"
        sx={{ color: "#ffff", zIndex: 105 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar_Bootstrap
        onLogout={props.onLogout}
        itemActive={0}
        title={"Dashboard"}
      />

      <div className="w-100 h-100">
        <div className=" d-flex justify-content-center bg-lightBlue col-lg-11-mine h-100 ms-auto p-1">
          <div className="col-12 col-lg-12 col-sm-12 pt-3 ">
            <div className="row mt-3 w-100 mt-5 mb-2 align-content-center d-flex justify-content-center ms-auto me-auto">
              {cardsRepState.map((item) => (
                <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
                  <div class="card rounded-15 ">
                    <div class="card-content">
                      <div class="card-body">
                        <div class="media row">
                          <div class="media-body text-left col-9 ">
                            <h6 className="">
                              {dataCardsReportState[item.report_state][1]}
                            </h6>
                            <h2
                              class={
                                "fw-bold text-" +
                                dataCardsReportState[item.report_state][0]
                              }
                            >
                              {item.count}
                            </h2>
                            <span className={"text-success"}>
                              {((item.count * 100) / totalsReports).toFixed(1) +
                                "% del total"}
                            </span>
                          </div>
                          <div
                            className={
                              "align-self-center rounded-3 col-3 d-flex justify-content-center "
                            }
                          >
                            <i
                              style={{ fontSize: "50px", height: "50px" }}
                              class={
                                "bx primary  float-right text-" +
                                dataCardsReportState[item.report_state][0] +
                                " bx-" +
                                dataCardsReportState[item.report_state][2]
                              }
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row mt-1 w-100 mb-3 align-content-center d-flex justify-content-center ms-auto me-auto">
              <div className="col-lg-7 col-md-12 col-sm-12 p-2">
                <div className="border bg-white rounded-15 col-12">
                  <h5 className="m-2 text-center">Reportes por especialidad</h5>
                  <Chart
                    className=" mt-2"
                    height={300}
                    options={chartOptionsBarEspec}
                    series={chartSeriesBar}
                    type="bar"
                    width="100%"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 p-2">
                <div className="border bg-white rounded-15 col-12 ">
                  <h5 className="m-2 text-center">Asignaciones por estado</h5>
                  <Chart
                    className=" mt-2 p-2"
                    height={300}
                    options={chartOptionsPieAssigns}
                    series={chartSeriesPie}
                    type="pie"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-1 w-100 mb-5 align-content-center d-flex justify-content-center ms-auto me-auto">
              <div className="col-lg-9 col-md-12 col-sm-12 p-2">
                <div className="border bg-white rounded-15 w-100 ">
                  <div className="row col-11-mine ms-auto me-auto ">
                    <div className="col-lg-5 col-md-12 col-sm-12 p-2 d-flex justify-content-between align-items-center">
                      <h5>Reportes por especialistas</h5>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 ">
                      <div className="row w-100 d-flex justify-content-between align-items-center">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-6 p-2">
                          <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            options={specialList}
                            renderInput={(params) => (
                              <TextField {...params} label="Especialista" />
                            )}
                            onChange={(event, newValue) => {
                              onSelectSpecialist(newValue);
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-0 m-0">
                          <Button startIcon={<FileUploadIcon/>}
                          disabled={specialSelect === -1}
                           onClick={()=>getInformExcel(specialSelect)} className="button-Primary col-11">
                            Detalle
                          </Button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-0 m-0">
                          <Button startIcon={<FileUploadIcon/>}
                           onClick={()=>getInformExcel()} className="button-Primary col-11">
                            Todos
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mb-3 col-11 ms-auto me-auto mt-4 h-100">
                    { loadingR
                    ?
                    <Box sx={{ m: 1, position: 'relative', minHeight: 200 }} >                   
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#3c78bc',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                 </Box>
                    :
                    
                    reportsBySpecialist && reportsBySpecialist.length === 0 ? (
                      <div className="h-100 w-100 text-center">
                        <img
                          className="imgPerfilUserProfile ms-auto me-auto"
                          src={imageNotFound}
                        />
                        <h6 className="text-center mt-3 mb-3">
                          Sin información que mostrar
                        </h6>
                      </div>
                    ) : (
                      <New_Table_Customized
                        columns={columnsToFill}
                        dataLoad={reportsBySpecialist}
                        arrayOfStatus={[]}
                        onElementSelected={() => {}}
                        page={0}
                        buttonsArray={[]}
                        rowsPerPage={100}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 p-2">
                <div className="card border bg-white rounded-15 col-12 ">
                  <div className="card-header  col-12">
                    <h4>Top especialistas más solicitados</h4>
                  </div>
                  <div className="card-body col-12">
                    {topSpecialist.map((item, index) => (
                      <div className={"row w-100 "}>
                        <div className="col-2  p-1">
                          <div className="rounded-3 bg-superlight d-flex align-content-center justify-content-center">
                            <span className="text-center color-info fw-bold h4">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                        <div className="col-9 ">
                          <span className="text-center fw-semibold">
                            {item.name}
                          </span>
                        </div>
                       
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

let columnsToFill = [
  {
    id: "study_realized",
    label: "Estudio Realizado",
    minWidth: 170,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "accepted",
    label: "Aceptados",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "assigned",
    label: "Asignados",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "reassigned",
    label: "Reasignados",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "expired",
    label: "Expirados",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "refused",
    label: "Reasignados",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "completed",
    label: "Completados",
    minWidth: 100,
    align: "center",
    isObject: false,
    format: (value) => value.toLocaleString("en-US"),
  },
];

/*
    <div
                          class="progress mt-1 mb-0 shadow-Info "
                          style={{ height: "7px" }}
                        >
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: item.porciento + "%" }}
                            aria-valuenow={item.porciento}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                   
*/
